import * as React from 'react';
import { Box } from 'jsxstyle';
import YouTube from 'react-youtube';
import { sensorsGA } from '../../../../utils';

let clientWidth = 360;
let myWidth = (clientWidth - 80) / 2;
let myHeight = myWidth / 4 * 3;
class VideoListBlock extends React.Component {
  static propTypes = {};
  constructor(props) {
    super(props);
    this.state = {
      isPlay: false,
      youtubeId: ''
    };
  }
  componentDidMount() {
    this.getWidth();
  }

  onVideoStart = (isInSnaptube, id, type, index, moduleName) => {
    sensorsGA({
      category: 'Coronavirus',
      action: `${index}_${type}_videoClick_${moduleName}_` + isInSnaptube,
      label: id + ''
    });
  };
  getWidth = () => {
    clientWidth = document && document.documentElement.clientWidth;
    myWidth = (clientWidth - 80) / 2;
    myHeight = myWidth / 4 * 3;
    this.setState({
      clientWidth
    });
  };

  renderVideo = (
    title,
    showAllText,
    videoList,
    isInSnaptube,
    isLeft,
    moduleName,
    region,
    type,
    curIndex
  ) => {
    let { history } = this.props;
    return (
      <Box key={curIndex}>
        <Box position="relative">
          <Box
            textAlign={isLeft ? 'left' : 'right'}
            fontSize="18px"
            fontWeight="bold"
            color="#333"
            fontFamily="Roboto-Bold,Roboto"
            lineHeight="60px">
            {title}
          </Box>
          {videoList[0] &&
            videoList[0].type == 'video' && (
              <Box
                position="absolute"
                fontSize="14px"
                top="40%"
                textDecorationLine="underline"
                right={isLeft ? '10px' : ''}
                left={!isLeft ? '10px' : ''}
                color="#008DFF"
                props={{
                  onClick: () => {
                    sensorsGA({
                      category: 'Coronavirus',
                      action: 'showall' + moduleName,
                      label: isInSnaptube + ''
                    });
                    history.push(
                      `/coronavirus/videoList?region=${region}&ModuleName=${moduleName}`
                    );
                    // setTimeout(() => {
                    //   window.location.href = `${
                    //     window.location.origin
                    //   }/coronavirus/videoList?region=${region}&ModuleName=${moduleName}`;
                    // }, 200);
                  }
                }}>
                {showAllText}
              </Box>
            )}
        </Box>
        <Box display="flex" flexWrap="wrap">
          {videoList.map((v, index) => {
            if (index > 3) {
              return;
            }
            if (v.type == 'video') {
              return (
                <Box width="50%" key={index}>
                  <Box
                    margin="5px"
                    padding="5px"
                    key={index}
                    marginBottom="10px"
                    background="#fff"
                    props={{
                      onClick: () => {
                        sensorsGA({
                          category: 'Coronavirus',
                          action:
                            `${index}_${type}_videoPlay_${moduleName}` +
                            isInSnaptube,
                          label: v.id + ''
                        });
                        this.setState({
                          youtubeId: v.id,
                          moduleName,
                          currentIndex: index,
                          isPlay: true
                        });
                      }
                    }}
                    borderRadius="5px">
                    <Box position="relative">
                      <Box component="picture">
                        <source type="image/webp" srcSet={v.webposter} />
                        <Box
                          borderRadius="10px"
                          component="img"
                          width={`${myWidth}px`}
                          height={`${myHeight}px`}
                          props={{
                            src: v.poster,
                            loading: 'lazy',
                            width: `${myWidth}px`,
                            height: `${myHeight}px`
                          }}
                        />
                      </Box>
                    </Box>

                    <Box
                      fontSize={!isLeft ? '10px' : '12px'}
                      direction={!isLeft ? 'rtl' : 'ltr'}
                      fontWeight={500}
                      color="#434343"
                      padding="0 5px"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      display="-webkit-box"
                      WebkitBoxOrient="vertical"
                      WebkitLineClamp={2}
                      wordBreak="break-word"
                      wordWrap="break-word">
                      {v.title}
                    </Box>
                  </Box>
                </Box>
              );
            }
            return (
              <Box
                marginBottom="10px"
                key={index}
                background="#fff"
                props={{
                  onClick: () => {
                    sensorsGA({
                      category: 'Coronavirus',
                      action: 'articleClick' + isInSnaptube,
                      label: v.href
                    });
                    window.location.href = v.href;
                  }
                }}
                display="flex"
                borderRadius="10px">
                <Box width="50%">
                  <Box component="picture">
                    <source type="image/webp" srcSet={v.webposter} />
                    <Box
                      borderRadius="10px"
                      component="img"
                      width="100%"
                      props={{ src: v.poster, loading: 'lazy' }}
                    />
                  </Box>
                </Box>
                <Box width="50%" padding="0 10px">
                  <Box
                    marginBottom="10px"
                    fontSize={isLeft ? '10px' : '12px'}
                    direction={isLeft ? 'rtl' : 'ltr'}
                    color="#434343"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    display="-webkit-box"
                    WebkitBoxOrient="vertical"
                    WebkitLineClamp={4}
                    wordBreak="break-word"
                    wordWrap="break-word"
                    fontWeight="bold">
                    {v.title}
                  </Box>
                  <Box fontSize="14px" color="#434343">
                    {v.des}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };

  render() {
    let {
      VideoBlock,
      VideoList,
      ModuleList,
      isInSnaptube,
      isLeft,
      region,
      type
    } = this.props;
    let { isPlay, youtubeId, moduleName = '', currentIndex = '' } = this.state;
    let videoWidth = clientWidth * 9 / 16;
    return (
      <Box padding="0 20px" background="#FAFBFC">
        {isPlay && (
          <Box
            background="rgba(0,0,0,0.5)"
            position="fixed"
            top="0"
            left="0"
            zIndex={9999}
            width="100vw"
            height="100vh"
            props={{
              onClick: () => {
                this.setState({
                  isPlay: false
                });
              }
            }}>
            <Box
              width="6vw"
              position="absolute"
              zIndex={99}
              right="5vw"
              top="10vw"
              props={{
                onClick: () => {
                  this.setState({
                    isPlay: false
                  });
                }
              }}>
              <Box
                component="img"
                width="100%"
                props={{ src: require('../../asset/icon_X.png') }}
              />
            </Box>
            <Box position="relative" top="50%" transform="translateY(-50%)">
              <Box position="relative" paddingBottom="20px">
                <YouTube
                  videoId={youtubeId}
                  onPlay={() =>
                    this.onVideoStart(
                      isInSnaptube,
                      youtubeId,
                      type,
                      currentIndex,
                      moduleName
                    )
                  }
                  opts={{
                    width: '100%',
                    height: videoWidth,
                    playerVars: {
                      autoplay: 1
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
        <Box display="none">
          <YouTube videoId={youtubeId} />
        </Box>

        {VideoList && VideoList.map((v, index) => {
          return this.renderVideo(
            VideoBlock[v],
            VideoBlock['showAll'],
            ModuleList[v],
            isInSnaptube,
            isLeft,
            v,
            region,
            type,
            index
          );
        })}
      </Box>
    );
  }
}

export default VideoListBlock;
