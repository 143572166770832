import { defaults } from '@mobiuspace/request'
import ReactGA from 'react-ga'

const isProd = process.env.NODE_ENV === 'production'

export const request =
  typeof window !== 'undefined' &&
  defaults({
    defaultBase: isProd ? 'https://api.getsnap.link' : 'http://localhost:3010'
  })

export const pick = (target, paths = []) =>
  paths.reduce(
    (result, path) => ({
      ...result,
      [path]: target[path]
    }),
    {}
  )

export const sensorsGA = param => {
  const { category, action, label, needUnderLine = true } = param

  ReactGA.event({
    category,
    action,
    label
  })

  if (typeof window !== 'undefined') {
    const sensors = require('sa-sdk-javascript')
    sensors.track(needUnderLine ? category && category.replace(' ', '_') : category, {
      action: needUnderLine ? action && action.replace(' ', '_') : action,
      label
    })
  }
}

const exchange = {
  egypt: 1,
  //阿尔及利亚
  algeria: 6.98,
  //伊拉克
  iraq: 69.48,
  // 约旦, 摩洛哥
  jordan: 0.041,
  morocco: 0.56
}

export const exchangeRate = (money, country) => {
  return money * exchange[country]
}

export const prettyNumber = num => {
  if (num > 1000) {
    return (num / 1000).toFixed(1) + 'k'
  }

  if (num > 1000000) {
    return (num / 1000000).toFixed(1) + 'm'
  }

  if (num > 1000000) {
    return (num / 1000000).toFixed(1) + 'b'
  }

  return num
}
