import * as React from 'react';
import { Box } from 'jsxstyle';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import { sensorsGA } from '../../../utils';
import queryString from 'query-string';
import Share from '../../../Share';
import countryJson from '../json/country.json';
import LocalBlock from './Modal/LocalBlock';
import WorldBlock from './Modal/WorldBlock';
import VideoListBlock from './Modal/VideoBlock';

let t1 = 100;
let clientWidth = 360;

const ButtonItem = ({ title, onClick, isLeft }) => {
  return (
    <Box
      flex={1}
      display="flex"
      width="20vw"
      position="absolute"
      top="10px"
      right={isLeft ? '20px' : ''}
      left={!isLeft ? '20px' : ''}
      justifyContent="center"
      borderRadius="20px"
      border="1px solid #76D3EE"
      background={'rgba(239,251,255,1)'}
      alignItems="center"
      props={{
        onClick
      }}>
      <Box
        textAlign="center"
        padding="5px 0"
        fontSize="10px"
        fontFamily="Roboto-Black,Roboto"
        fontWeight={900}
        color={'#006280'}>
        {title}
      </Box>
    </Box>
  );
};

const timeoffsetList = countryJson.timeoffsetList;
const rarList = countryJson.rarList;
const renList = countryJson.renList;
const resList = countryJson.resList;
const rptList = countryJson.rptList;
const leftList = countryJson.leftList;

class NewIndex extends React.Component {
  static propTypes = {
    location: PropTypes.any,
    title: PropTypes.any,
    onClick: PropTypes.any,
    isLeft: PropTypes.any,
    isInSnaptube: PropTypes.any
  };
  constructor(props) {
    super(props);
    this.state = {
      myConfig: {},
      myData: {},
      myHistory: {},
      tableColor: {},
      dataParams: {},
      isInSnaptube: false,
      area: 'RAR',
      region: 'EG',
      isLeft: false,
      showFloatButton: false,
      currentType: 'local',
      tabStyle: 'relative',
      worldMapConfirm: true,
      worldConfirm: true,
      localAdd: true,
      isModalShow: false,

      summaryHistory: [],
      WorldWide: {},
      worldOption: {},
      updateTime: '',
      isScale: true,
      localClick: 'newCases',
      worldClick: 'newCases',
      isPush: false,
      currentVideoInfo: {},
    }
  }

  initData = (props) => {
    let { ssrData, isLeft, region, isInSnaptube, area } = props
    let windowData = ssrData
    let item = windowData && windowData.Item;
    if (item) {


      let myConfig = item.config;
      let myData = item.foreignData;
      //处理左右阅读习惯
      let dataParams = {};
      if (isLeft) {
        dataParams = {
          updateTime: this.dealCountryTime(myData.countryData.date, region, isLeft),
          countryTotal: myData.countryData.total,
          countryPresent: myData.countryData.present,
          countryHeal: myData.countryData.heal,
          countryDead: myData.countryData.dead,
          countryNewTotal: myData.countryData.newTotal,
          countryNewPresent: myData.countryData.newPresent,
          countryNewHeal: myData.countryData.newHeal,
          countryNewDead: myData.countryData.newDead,
          worldTotal: myData.worldData.worldTotal,
          worldPresent: myData.worldData.worldPresent,
          worldHeal: myData.worldData.worldHeal,
          worldDead: myData.worldData.worldDead,
          worldNewTotal: myData.worldData.worldNewTotal,
          worldNewPresent: myData.worldData.worldNewPresent,
          worldNewHeal: myData.worldData.worldNewHeal,
          worldNewDead: myData.worldData.worldNewDead
        };
      } else {
        dataParams = {
          updateTime: this.dealCountryTime(myData.countryData.date, region, isLeft),
          countryTotal: myData.countryData.dead,
          countryPresent: myData.countryData.heal,
          countryHeal: myData.countryData.present,
          countryDead: myData.countryData.total,
          countryNewTotal: myData.countryData.newDead,
          countryNewPresent: myData.countryData.newHeal,
          countryNewHeal: myData.countryData.newPresent,
          countryNewDead: myData.countryData.newTotal,
          worldTotal: myData.worldData.worldDead,
          worldPresent: myData.worldData.worldHeal,
          worldHeal: myData.worldData.worldPresent,
          worldDead: myData.worldData.worldTotal,
          worldNewTotal: myData.worldData.worldNewDead,
          worldNewPresent: myData.worldData.worldNewHeal,
          worldNewHeal: myData.worldData.worldNewPresent,
          worldNewDead: myData.worldData.worldNewTotal
        };
      }



      this.setState({
        myConfig,
        myData,
        dataParams,
        area,
        isLeft,
        region,
        isInSnaptube
      });
    }
  }

  componentWillMount() {
    let { props } = this
    this.initData(props)
  }

  componentDidMount() {
    //滑动节流
    const debounce = (fn, wait) => {
      const self = this;
      let timeout = null;
      return function () {
        if (!timeout) {
          timeout = setTimeout(() => {
            timeout = null;
            fn(self);
          }, wait);
        }
      };
    };
    //控制列表不超过图片
    function pageScroll(self) {
      t1 = document.documentElement.scrollTop || document.body.scrollTop;

      if (t1 < 50) {
        self.setState({
          showFloatButton: false
        });
      } else {
        self.setState({
          showFloatButton: true
        });
      }
    }
    const { isInSnaptube } = this.props;
    let { region = 'EG', videoId = '' } = queryString.parse(
      this.props.location.search
    );
    if (Array.isArray(region)) {
      region = region[0];
    }
    let area = region;
    //check country
    if (resList.includes(region)) {
      area = 'RES';
    } else if (rarList.includes(region)) {
      area = 'RAR';
    } else if (renList.includes(region)) {
      area = 'REN';
    } else {
      area = 'RPT';
    }
    //判断是否为push页面
    if (videoId) {
      this.setState({
        videoId,
        isPush: true
      });
    }

    if (!leftList.includes(area)) {
      this.setState({
        isLeft: false
      });
    } else {
      this.setState({
        isLeft: true
      })
    }
    this.setState(
      {
        isInSnaptube,
        area,
        region
      },
      () => {
        this.getData();
      }
    );
    //判断用户是否在当前页面
    // const self = this;
    // function onVisibilityChange() {
    //   // 用户离开了当前页面
    //   if (document.visibilityState === 'visible') {
    //     init(isInSnaptube, area, region);
    //   }
    // }
    // if (document.visibilityState === 'visible') {
    //   this.setState(
    //     {
    //       isInSnaptube,
    //       area,
    //       region
    //     },
    //     () => {
    //       this.getData();
    //     }
    //   );
    // } else {
    //   document.addEventListener('visibilitychange', onVisibilityChange);
    // }

    // function init(isInSnaptube, area, region) {
    //   if (document.visibilityState === 'visible') {
    //     self.setState(
    //       {
    //         isInSnaptube,
    //         area,
    //         region
    //       },
    //       () => {
    //         self.getData();
    //       }
    //     );
    //     document.removeEventListener('visibilitychange', onVisibilityChange);
    //   }
    // }

    //记录端内外访问
    // sensorsGA({
    //   category: 'Coronavirus',
    //   action: 'pageview',
    //   label: isInSnaptube + ''
    // });
    clientWidth = document.documentElement.clientWidth;
    window && window.addEventListener('scroll', debounce(pageScroll, 200));
  }

  onVideoStart = (isInSnaptube, id) => {
    let wifistate = window && window.navigator.connection.type || 'none'
    sensorsGA({
      category: 'Coronavirus',
      action: wifistate + '_push_videoClick_' + isInSnaptube,
      label: id + ''
    });
  };

  //千分位处理
  format = (num, op) => {
    let { region } = this.state;
    let number = num || 0;
    let matchText = '$&,';
    if (region == 'BR') {
      matchText = '$&.';
    }
    if (op) {
      if (number >= 0) {
        number = '+' + number;
      }
    }
    let reg = /\d{1,3}(?=(\d{3})+$)/g;
    return (number + '').replace(reg, matchText);
  };
  //处理不同国家的时间
  dealCountryTime = (value, ssrRegion, ssrIsLeft) => {
    let region = ssrRegion || this.state.region
    let myTime = new Date(value).valueOf();
    let timeoffset = timeoffsetList[region] || 8;
    let countryTime = myTime - timeoffset * 60 * 60 * 1000;
    return this.formatTime(new Date(countryTime), ssrIsLeft);
  };
  formateTimeStr(num) {
    return num < 10 ? `0${num}` : num + '';
  }
  //格式化时间
  formatTime = (value, ssrIsLeft) => {
    let isLeft = typeof (ssrIsLeft) == 'undefined' ? this.state.isLeft : ssrIsLeft
    let year = value.getFullYear();
    let month = this.formateTimeStr(value.getMonth() + 1);
    let day = this.formateTimeStr(value.getDate());
    let hours = this.formateTimeStr(value.getHours());
    let min = this.formateTimeStr(value.getMinutes());
    if (!isLeft) {
      return `${hours}:${min} ${day}/${month}/${year}`;
    }
    return `${day}/${month}/${year} ${hours}:${min}`;
  };
  getData = async () => {
    let { isLeft, region, isPush, videoId } = this.state;
    let windowData = window && window.__Data__ || {}
    if (!windowData.Item) {
      let url = `https://me0xn4hy3i.execute-api.us-east-1.amazonaws.com/staging/api/coronavirus/newGet?lang=${region}`;
      await fetch(url, {
        method: 'get',
        headers: {}
      })
        .then(response => response.json())
        .then(res => {
          windowData = res.data
        });
    }

    let item = windowData && windowData.Item;
    if (!item) {
      return
    }
    let myConfig = item.config;
    let myData = item.foreignData;
    let myHistory = item.history;
    let pushList = item.pushList || [];
    console.log(myConfig);
    let tableColor = [];
    myConfig.CityColorIndex.map(v => {
      let newColor = v.substr(0, v.length - 1) + ',0.3)';
      tableColor.push(newColor);
    });
    //获取push视频信息
    if (isPush) {
      let currentVideoList = [];
      currentVideoList = pushList.filter(v => {
        return v.id == videoId;
      });
      if (currentVideoList.length == 0) {
        this.setState({
          isPush: false
        });
      } else {
        this.setState({
          currentVideoInfo: currentVideoList[0]
        });
      }
    }
    //处理左右阅读习惯
    let dataParams = {};
    if (isLeft) {
      dataParams = {
        updateTime: this.dealCountryTime(myData.countryData.date),
        countryTotal: myData.countryData.total,
        countryPresent: myData.countryData.present,
        countryHeal: myData.countryData.heal,
        countryDead: myData.countryData.dead,
        countryNewTotal: myData.countryData.newTotal,
        countryNewPresent: myData.countryData.newPresent,
        countryNewHeal: myData.countryData.newHeal,
        countryNewDead: myData.countryData.newDead,
        worldTotal: myData.worldData.worldTotal,
        worldPresent: myData.worldData.worldPresent,
        worldHeal: myData.worldData.worldHeal,
        worldDead: myData.worldData.worldDead,
        worldNewTotal: myData.worldData.worldNewTotal,
        worldNewPresent: myData.worldData.worldNewPresent,
        worldNewHeal: myData.worldData.worldNewHeal,
        worldNewDead: myData.worldData.worldNewDead
      };
    } else {
      dataParams = {
        updateTime: this.dealCountryTime(myData.countryData.date),
        countryTotal: myData.countryData.dead,
        countryPresent: myData.countryData.heal,
        countryHeal: myData.countryData.present,
        countryDead: myData.countryData.total,
        countryNewTotal: myData.countryData.newDead,
        countryNewPresent: myData.countryData.newHeal,
        countryNewHeal: myData.countryData.newPresent,
        countryNewDead: myData.countryData.newTotal,
        worldTotal: myData.worldData.worldDead,
        worldPresent: myData.worldData.worldHeal,
        worldHeal: myData.worldData.worldPresent,
        worldDead: myData.worldData.worldTotal,
        worldNewTotal: myData.worldData.worldNewDead,
        worldNewPresent: myData.worldData.worldNewHeal,
        worldNewHeal: myData.worldData.worldNewPresent,
        worldNewDead: myData.worldData.worldNewTotal
      };
    }
    this.setState({
      myConfig,
      myData,
      myHistory,
      tableColor,
      dataParams
    });

  };

  render() {
    let {
      BackgroundBlock = {},
      Local = {},
      WorldWide = {},
      BackgroundColor = {},
      DataColor = {},
      FeedBackBlock = {},
      ShareBlock = {},
      VideoBlock = {},
      VideoList = [],
      ModuleList = []
    } = this.state.myConfig;
    let {
      isPush,
      isInSnaptube,
      area,
      region,
      isLeft,
      isModalShow,
      dataParams = {},
      currentVideoInfo
    } = this.state;

    let videoWidth = clientWidth * 9 / 16;

    let shareDes = ShareBlock.ShareDes || '';
    return (
      <Box
        width="100%"
        minWidth="100vw"
        minHeight="100vh"
        position="absolute"
        top="0"
        paddingBottom="15vh"
        background="#FFF">
        {/* 弹出框 */}
        {isModalShow && (
          <Box
            width="100%"
            height="100vh"
            position="fixed"
            top={0}
            left={0}
            zIndex={9999}>
            <Box
              width="100vw"
              height="100vh"
              background="rgba(0,0,0,0.5)"
              position="absolute"
              zIndex={9}
              props={{
                onClick: () => {
                  this.setState({
                    isModalShow: false
                  });
                }
              }}
              top={0}
            />
            <Box
              width="6vw"
              position="absolute"
              zIndex={99}
              right="5vw"
              top="5vw"
              props={{
                onClick: () => {
                  this.setState({
                    isModalShow: false
                  });
                }
              }}>
              <Box
                component="img"
                width="100%"
                props={{ src: require('../asset/icon_X.png') }}
              />
            </Box>
            <Box
              width="80%"
              margin="auto"
              textAlign={isLeft ? 'left' : 'right'}
              zIndex={9}
              position="relative"
              top="10vh">
              <Box
                component="img"
                width="100%"
                props={{
                  src: require(`../asset/pic_win.png`)
                }}
              />
              <Box
                position="relative"
                top="-5px"
                minHeight="50vh"
                background="#fff"
                padding="30px 20px 60px"
                borderRadius="0 0 20px 20px ">
                <Box
                  fontSize="18px"
                  fontWeight="bold"
                  color="#434343"
                  paddingBottom="18px">
                  {BackgroundBlock.TypicalSymptoms}
                </Box>
                <Box
                  fontSize="14px"
                  color="#434343"
                  whiteSpace="pre-line"
                  position="relative"
                  overflow="scroll"
                  maxHeight="40vh">
                  {BackgroundBlock.TypicalShow}
                </Box>
                {FeedBackBlock.feedBack && (
                  <Box
                    fontSize="12px"
                    width="100%"
                    left="0"
                    textAlign="center"
                    position="absolute"
                    bottom="20px"
                    fontWeight="bold"
                    fontStyle="italic">
                    {isLeft && (
                      <Box display="inline-block" color="#434343">
                        {FeedBackBlock.feedBack}
                      </Box>
                    )}

                    <Box
                      color="#50C4FF"
                      padding="0 10px"
                      textDecoration="none"
                      component="a"
                      props={{
                        onClick: () => {
                          sensorsGA({
                            category: 'Coronavirus',
                            action: 'emailModalClick',
                            label: isInSnaptube + ''
                          });
                        },
                        href: FeedBackBlock.href
                      }}>
                      {FeedBackBlock.label}
                    </Box>
                    {!isLeft && (
                      <Box display="inline-block" color="#434343">
                        {FeedBackBlock.feedBack}
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )}

        {/* 数据来源说明 */}
        {isPush ? (
          <Box>
            <Box position="relative" borderBottom="1px solid #EFEFEF" paddingTop={videoWidth}>
              <Box position='absolute' width='100%' top={0}>
                <YouTube
                  videoId={currentVideoInfo.id}
                  onPlay={() =>
                    this.onVideoStart(isInSnaptube, currentVideoInfo.id)
                  }
                  opts={{
                    width: '100%',
                    height: videoWidth,
                    playerVars: {
                      autoplay: 1
                    }
                  }}
                />
              </Box>
              <Box
                fontSize="16px"
                fontWeight={500}
                color="#434343"
                padding="10px"
                overflow="hidden"
                textOverflow="ellipsis"
                display="-webkit-box"
                WebkitBoxOrient="vertical"
                WebkitLineClamp={2}
                wordBreak="break-word"
                wordWrap="break-word">
                {currentVideoInfo.title}
              </Box>
            </Box>
          </Box>
        ) : (
            <Box position="relative" paddingBottom='35vw'>
              <Box
                position='absolute'
                component="img"
                width="100%"
                props={{
                  id: 'backPic',
                  src: require(`../asset/${area}/pic_banner.png`)
                }}
              />
              <Box
                position="absolute"
                width="25vw"
                top="1vh"
                right="0"
                component="a"
                props={{
                  onClick: () => {
                    sensorsGA({
                      category: 'Coronavirus',
                      action: isPush
                        ? 'emailPageClick_push'
                        : 'emailPageClick_landingpage',
                      label: isInSnaptube + ''
                    });
                  },
                  href: FeedBackBlock.href
                }}>
                <Box
                  component="img"
                  width="100%"
                  props={{
                    src: require(`../asset/${area}/suggestion.png`)
                  }}
                />
              </Box>
              <Box
                position="absolute"
                bottom="10%"
                width="80%"
                left="10%"
                props={{
                  onClick: () => {
                    sensorsGA({
                      category: 'Coronavirus',
                      action: 'datasFrom',
                      label: isInSnaptube + ''
                    });
                    this.setState({
                      isModalShow: true
                    });
                  }
                }}>
                <Box
                  component="img"
                  width="100%"
                  props={{
                    src: require(`../asset/${area}/datas.png`)
                  }}
                />
              </Box>
            </Box>
          )}

        {/* 全球和本国的tab */}

        <Box>
          {/* 本国模块 */}
          <Box>
            <Box
              textAlign={isLeft ? 'left' : 'right'}
              padding="10px 20px"
              position="relative">
              <ButtonItem
                title={Local.MoreData}
                isLeft={isLeft}
                onClick={() => {
                  sensorsGA({
                    category: 'Coronavirus',
                    action: isPush
                      ? 'localMoreData_push'
                      : 'localMoreData_landingpage',
                    label: isInSnaptube + ''
                  });

                  let pushUrl = isInSnaptube
                    ? `coronavirus/detail?region=${region}&pn=com.snaptube.premium`
                    : `coronavirus/detail?region=${region}`;
                  this.props.history.push(pushUrl);
                  // setTimeout(() => {
                  //   window.location.href = `${
                  //     window.location.origin
                  //   }/coronavirus/detail?region=${region}`;
                  // }, 200);
                }}
              />
              <Box>
                <Box
                  fontSize="18px"
                  fontWeight="bold"
                  color="#333"
                  fontFamily="Roboto-Bold,Roboto"
                  lineHeight="24px">
                  {Local.DataUpdatedIn}
                </Box>
              </Box>
            </Box>
            <Box>
              <Box
                margin="0 20px"
                overflow="hidden"
                border-radius="5px "
                border="2px solid rgba(238,238,238,1) ">
                <LocalBlock
                  DataBackgroundColor={BackgroundColor}
                  isShowHeal={Local.isShowHealData}
                  isLeft={isLeft}
                  dataParams={dataParams}
                  Local={Local}
                  DataColor={DataColor}
                  region={region}
                />
              </Box>
            </Box>
          </Box>
          {/* 全球模块  */}
          <Box>
            <Box
              padding="10px 20px"
              position="relative"
              textAlign={isLeft ? 'left' : 'right'}>
              <ButtonItem
                title={Local.MoreData}
                isLeft={isLeft}
                onClick={() => {
                  sensorsGA({
                    category: 'Coronavirus',
                    action: isPush
                      ? 'worldMoreData_push'
                      : 'worldMoreData_landingpage',
                    label: isInSnaptube + ''
                  });

                  let pushUrl = isInSnaptube
                    ? `coronavirus/detail?region=${region}&anchor=world&pn=com.snaptube.premium`
                    : `coronavirus/detail?region=${region}&anchor=world`;
                  this.props.history.push(pushUrl);
                  // setTimeout(() => {
                  //   window.location.href = `${
                  //     window.location.origin
                  //   }/coronavirus/detail?region=${region}&anchor=world`;
                  // }, 200);
                }}
              />
              <Box>
                <Box
                  fontSize="18px"
                  fontWeight="bold"
                  color="#333"
                  fontFamily="Roboto-Bold,Roboto"
                  lineHeight="24px">
                  {WorldWide.WorldwideSituation}
                </Box>
                {/* <Box
                  fontSize="12px"
                  color="#333"
                  fontWeight={400}
                  fontFamily="Roboto-Bold,Roboto">
                  {!isLeft && <span>{dataParams.updateTime}</span>}
                  {WorldWide.DataUpdateOn}
                  {isLeft && <span>{dataParams.updateTime}</span>}
                </Box> */}
              </Box>
            </Box>
            <Box
              margin="0 20px"
              overflow="hidden"
              border-radius="5px "
              border="2px solid rgba(238,238,238,1) ">
              <WorldBlock
                DataBackgroundColor={BackgroundColor}
                dataParams={dataParams}
                WorldWide={WorldWide}
                DataColor={DataColor}
                region={region}
              />{' '}
            </Box>
          </Box>
        </Box>

        <Box
          fontSize="11px"
          color="#333"
          padding="10px 20px"
          textAlign={isLeft ? 'left' : 'right'}
          fontWeight={400}
          fontFamily="Roboto-Bold,Roboto">
          {!isLeft && <span>{dataParams.updateTime}</span>}
          {Local.DataUpdateOn}
          {isLeft && <span>{dataParams.updateTime}</span>}
        </Box>
        <Box>
          {FeedBackBlock.feedBack &&
            isPush && (
              <Box
                fontSize="12px"
                width="100%"
                padding="10px"
                left="0"
                textAlign="center"
                fontWeight="bold"
                fontStyle="italic">
                {isLeft && (
                  <Box display="inline-block" color="#434343">
                    {FeedBackBlock.feedBack}
                  </Box>
                )}
                <Box
                  color="#50C4FF"
                  margin="0 10px"
                  textDecoration="none"
                  component="a"
                  props={{
                    onClick: () => {
                      sensorsGA({
                        category: 'Coronavirus',
                        action: 'emailModalClick',
                        label: isInSnaptube + ''
                      });
                    },
                    href: FeedBackBlock.href
                  }}>
                  {FeedBackBlock.label}
                </Box>
                {!isLeft && (
                  <Box display="inline-block" color="#434343">
                    {FeedBackBlock.feedBack}
                  </Box>
                )}
              </Box>
            )}
        </Box>

        {/* 文章模块 */}
        {VideoBlock.count && (
          <VideoListBlock
            isLeft={isLeft}
            history={this.props.history}
            VideoBlock={VideoBlock}
            VideoList={VideoList}
            ModuleList={ModuleList}
            isInSnaptube={isInSnaptube}
            region={region}
            type={isPush ? 'push' : 'landingpage'}
          />
        )}

        {
          !isInSnaptube &&
          <Box width="50%" margin='20px auto 0 ' >
            <Box
              component="img"
              width="100%"
              props={{
                onClick: () => {
                  sensorsGA({
                    category: 'Coronavirus',
                    action: isPush
                      ? 'download_push'
                      : 'download_landingpage',
                    label: isInSnaptube + ''
                  });
                  window.location.href =
                    ' http://dl-master.snaptube.app/installer/snaptube/latest/Click_me_to_install_SnapTube_tube_coronavirus.apk';
                },
                src: require(`../asset/${area}/btn_download.png`)
              }}
            />
          </Box>
        }

        {/* 悬浮下载分享按钮 */}
        {this.state.showFloatButton && (
          <Share
            uri={`https://share.getsnap.link/campaigns/coronavirus/coronavirus?region=${region}`}
            title={shareDes}
            image={require('../asset/sharePic.png')}
            campaign="coronavirus"
            description=""
            isInSnaptube={isInSnaptube}
            isNativeShareUriShorten={true}>
            {({ onClick }) => (
              <Box>
                <Box
                  zIndex={9}
                  width="100%"
                  background="rgba(255,255,255,0.7)"
                  paddingTop="5px"
                  position="fixed"
                  bottom="0">
                  <Box
                    position="relative"
                    props={{
                      onClick: () => {
                        sensorsGA({
                          category: 'Coronavirus',
                          action: isPush ? 'share_push' : 'share_landing',
                          label: isInSnaptube + ''
                        });
                        onClick();
                      }
                    }}>
                    <Box position='relative' width='100%'>
                      <Box
                        position="relative"
                        left='50%'
                        transform='translateX(-50%)'
                        component="img"
                        width="70%"
                        props={{ src: require(`../asset/${area}/btn_share_l.png`) }}
                      />
                    </Box>
                    <Box fontSize='12px' textAlign='center' color='#8C8C8C' position='relative' top='-5px'>
                      {ShareBlock.ShareTips}
                    </Box>
                  </Box>


                </Box>

              </Box>
            )}
          </Share>
        )}
      </Box>
    );
  }
}

export default NewIndex;
