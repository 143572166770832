import React, { Component, Children, cloneElement } from 'react'
import { sensorsGA } from '../utils'
import { Row, Inline } from 'jsxstyle'
import PropTypes from 'prop-types'
import { parse, extract, stringify } from 'query-string'

import { getQuery } from '@mobiuspace/utils'
import Overlay from '@mobiuspace/overlay'
import { defaults } from '@mobiuspace/request'
import SocialSharing from 'react-social-share-button'

const isProd = process.env.NODE_ENV === 'production'

const getUri = ({ uri, source, content, campaign }) => {
  const { origin, search, pathname } = window.location
  const query = stringify({
    ...parse(getQuery(search)),
    ...parse(extract(uri)),
    utm_source: source,
    utm_content: content,
    utm_campaign: campaign
  })

  const path = uri.split('?')[0] || `${origin}${pathname}`

  return `${path}?${query}`
}

const request = defaults({ defaultBase: 'https://getsnap.link' })

const shortenUri = async url => {
  const { data, error } = await request.post('/generate', { body: { url } })

  if (data) {
    return data.shortUrl
  } else {
    throw error
  }
}

/**
 * In house social sharing framework, works both inside or outside of Snaptube's
 * web-view
 */
export default class Share extends Component {
  static propTypes = {
    /**
     * URI to share on social platform
     */
    uri: PropTypes.string.isRequired,
    /**
     * Preview image's URI for sharing
     */
    image: PropTypes.string.isRequired,
    /**
     * Sharing page's title
     */
    title: PropTypes.string.isRequired,
    /**
     * UTM source appending to URI
     */
    source: PropTypes.string,
    /**
     * UTM content appending to URI
     */
    content: PropTypes.string,
    /**
     * UTM campaign appending to URI
     */
    campaign: PropTypes.string.isRequired,
    /**
     * Child element to wrap and activate sharing when clicked
     */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    /**
     * Function to run when shared on a Web
     */
    onWebShare: PropTypes.func,
    /**
     * Sharing page's description
     */
    description: PropTypes.string,
    /**
     * Google Analytics' eventAction parameter when tracking event
     */
    eventAction: PropTypes.string,
    /**
     * If inside of Snaptube's web-view
     */
    isInSnaptube: PropTypes.bool.isRequired,
    /**
     * @ignore
     * Exist for compatibility reason
     */
    isInSnapTube: PropTypes.bool,
    /**
     * Enabled platforms to share outside of Snaptube's web-view
     */
    webPlatforms: PropTypes.arrayOf(PropTypes.string),
    /**
     * Which Facebook App ID to use when sharing to Facebook
     */
    facebookAppId: PropTypes.string,
    /**
     * Container element's width for web sharing
     */
    containerWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * White-listed of platforms to share inside of Snaptube's web-view
     */
    nativePlatforms: PropTypes.arrayOf(PropTypes.string),
    /**
     * Function to run when shared to a platform successfully inside of
     * Snaptube's web-view
     */
    onNativeShareSuccess: PropTypes.func,
    /**
     * Function to run when sharing canceled inside of Snaptube's web-view
     */
    onNativeShareCanceled: PropTypes.func,
    /**
     * Shorten URI before sending in Snaptube
     */
    isNativeShareUriShorten: PropTypes.bool
  }

  static defaultProps = {
    source: 'social',
    description: '',
    eventAction: 'Share',
    isInSnaptube: false,
    webPlatforms: ['facebook', 'messenger', 'whatsapp'],
    facebookAppId: isProd ? '462239440859485' : '320748111662845',
    nativePlatforms: [
      'com.whatsapp',
      'com.facebook.katana',
      'com.facebook.orca',
      'com.twitter.android',
      'sms',
      'email',
      'com.tencent.mm',
      'com.snapchat.android',
      'jp.naver.line.android'
    ],
    isNativeShareUriShorten: true
  }

  state = {
    isWebShareShown: false
  }

  onNativeShare = () => {
    const {
      uri,
      image,
      title,
      source,
      content,
      campaign,
      description,
      eventAction,
      nativePlatforms,
      onNativeShareSuccess,
      onNativeShareCanceled,
      isNativeShareUriShorten
    } = this.props
    const params = {
      desc: description,
      title,
      imgUrl: image
    }

    window.snaptube.exec(
      'ui',
      'showShare',
      {
        ...params,
        platforms: nativePlatforms
      },
      async response => {
        const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data
        const { cancel } = data

        if (cancel) {
          sensorsGA({
            category: 'Native Share',
            action: 'Cancel',
            label: ''
          })

          if (onNativeShareCanceled) {
            onNativeShareCanceled()
          }
        } else {
          const { token, platform } = data
          const longUrl = getUri({
            uri,
            source,
            content: content || platform,
            campaign
          })

          let linkUrl = longUrl
          if (isNativeShareUriShorten) {
            linkUrl = await shortenUri(longUrl)
          }

          window.snaptube.exec('ui', 'doShare', {
            ...params,
            token,
            linkUrl,
            platform
          })

          sensorsGA({
            category: 'Native Share',
            action: eventAction,
            label: platform
          })

          if (onNativeShareSuccess) {
            onNativeShareSuccess()
          }
        }
      }
    )
  }

  onWebShare = data => {
    const { onWebShare, eventAction } = this.props

    sensorsGA({
      category: 'Web Share',
      action: eventAction,
      label: data.type
    })

    if (onWebShare) {
      onWebShare(data)
    }
  }

  onToggleWebShare = () =>
    this.setState(
      ({ isWebShareShown }) => ({
        isWebShareShown: !isWebShareShown
      }),
      () => {
        const { isWebShareShown } = this.state

        sensorsGA({
          category: 'Web Share',
          action: isWebShareShown ? 'Show' : 'Close',
          label: ''
        })
      }
    )

  getWebShareUri = async ({ type }) => {
    const { uri, source, content, campaign } = this.props

    return encodeURIComponent(getUri({ uri, source, content: content || type, campaign }))
  }

  render() {
    const { isWebShareShown } = this.state
    const { title, children, isInSnaptube, isInSnapTube, webPlatforms, facebookAppId, containerWidth } = this.props
    const inSnaptube = isInSnaptube || isInSnapTube
    const onClick = inSnaptube ? this.onNativeShare : this.onToggleWebShare
    const child =
      typeof children === 'function' ? children({ onClick }) : cloneElement(Children.only(children), { onClick })

    if (inSnaptube) {
      return child
    } else {
      return (
        <Inline width={containerWidth}>
          {child}

          <Overlay isShown={isWebShareShown} onClose={this.onToggleWebShare}>
            <Row
              padding="20px"
              boxShadow="0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0)"
              background="#fff"
              borderRadius="3px"
            >
              {webPlatforms.map(platform => {
                const props =
                  platform === 'facebook'
                    ? {
                        'data-param-app_id': facebookAppId
                      }
                    : undefined

                return (
                  <SocialSharing
                    key={platform}
                    url={this.getWebShareUri}
                    type={platform}
                    title={title}
                    onShare={this.onWebShare}
                    {...props}
                  />
                )
              })}
            </Row>
          </Overlay>
        </Inline>
      )
    }
  }
}
