import * as React from 'react';
import { Box } from 'jsxstyle';
import { sensorsGA } from '../../../../utils';


let t1 = 0;
let t2 = 100;
class CityBlock extends React.Component {
  static propTypes = {};
  constructor(props) {
    super(props);
    this.state = {
      isScale: true,
      visible: false,
      tabStyle: 'relative'
    };
  }
  componentDidMount() {

    const self = this;
    var intersectionObserver = new IntersectionObserver(function (entries) {
      if (entries[0].target.id == 'cityBlock') {
        if (entries[0].intersectionRatio > 0) {
          self.setState({
            visible: true
          });
        } else {
          self.setState({
            tabStyle: 'relative',
            visible: false
          });
        }
      }
    });

    intersectionObserver.observe(document.querySelector('#cityBlock'));
    //滑动节流
    const debounce = (fn, wait) => {
      const self = this;
      let timeout = null;
      return function () {
        if (!timeout) {
          timeout = setTimeout(() => {
            timeout = null;
            fn(self);
          }, wait);
        }
      };
    };
    function pageScroll(self) {
      let { visible, isScale } = self.state
      if (!isScale && visible) {
        t1 = document.documentElement.scrollTop || document.body.scrollTop;
        t2 = document.getElementById('cityBlock').offsetTop;

        if (t1 > t2 - 5) {
          self.setState({
            tabStyle: 'fixed'
          });
        } else {
          self.setState({
            tabStyle: 'relative'
          });
        }
      }

    }
    window.addEventListener('scroll', debounce(pageScroll, 200), {
      passive: true
    });
  }

  //千分位处理
  format = (num, op) => {
    let { region } = this.props;
    let number = num || 0;
    let matchText = '$&,';
    if (region == 'BR') {
      matchText = '$&.';
    }
    if (op) {
      if (number >= 0) {
        number = '+' + number;
      }
    }
    let reg = /\d{1,3}(?=(\d{3})+$)/g;
    return (number + '').replace(reg, matchText);
  };

  render() {
    let {
      CityBlock = {},
      CityIndex = [],
      CityColorIndex = [],
      CityList = [],
      isInSnaptube
    } = this.props;

    CityIndex = CityIndex.filter(v => {
      if (v != " ") {
        return v
      }
    })

    let { isScale, tabStyle } = this.state;
    return (
      <Box
        backgroundColor="#fff"
        borderRadius="0 0 40px 40px"
        position="relative"
        props={{
          id: 'cityBlock'
        }}
        top="-2px">

        {/* <Box> {CityBlock.title}</Box> */}
        <Box
          color="#434343"
          fontSize="16px"
          lineHeight="16px"
          margin="0 20px"
          padding="20px 0"
          textAlign="center"
          fontWeight={600}>
          {CityBlock.title}
        </Box>
        <Box
          margin="0 0 40px"
          overflowX="hidden">
          <table cellSpacing="0" width="100%" >
            <tbody>
              <Box component="tr" props={{ id: 'cityHeader' }} position={tabStyle} top={tabStyle == 'fixed' ? '9vw' : ''} zIndex={999}>
                {//生成表格头部
                  CityIndex.map((v, index) => {
                    return (
                      <Box
                        textAlign="center"
                        key={index}
                        borderRadius="2px"
                        padding="15px 0"
                        width={"20%"}
                        fontSize="14px"
                        fontWeight={600}
                        backgroundColor={CityColorIndex[index]}
                        color={'#434343'}
                        component="td">
                        {CityBlock[v]}
                      </Box>
                    );
                  })}
              </Box>
            </tbody>
          </table>
          <table cellSpacing="0" width="100%">
            <tbody>
              {CityList.map((v, index) => {
                if (isScale) {
                  if (index < 5) {
                    return (
                      <Box component="tr" key={index}
                        background={v.isCity ? "#fff" : (index % 2 ? '#EBF6F8' : '#F4F9FA')} props={{
                          onClick: () => {
                            if (v.isCity) {
                              let currentList = JSON.parse(JSON.stringify(v.cityList))
                              let currentCityList = CityList
                              if (v.isScale) {
                                currentList.unshift(index + 1, 0)
                                currentCityList[index].isScale = false
                                Array.prototype.splice.apply(currentCityList, currentList);
                              } else {
                                currentCityList[index].isScale = true
                                let delCount = currentList.length
                                currentCityList.splice(index + 1, delCount)

                              }
                              this.setState({
                                CityList: currentCityList
                              })
                            }

                          }
                        }
                        }>
                        {
                          CityIndex.map((cityTitle, index) => {
                            return (
                              <Box
                                textAlign="center"
                                key={index}
                                width={"20%"}
                                padding="10px 0"
                                fontSize="11px"
                                fontWeight={500}
                                color={'#434343'}
                                borderBottom={v.isCity ? '1px solid #cfcfcf' : ""}
                                component="td">
                                <Box

                                  justifyContent='center'
                                  alignItems='center'
                                  margin={cityTitle == 'city' ? 'auto' : ""}
                                  display={cityTitle == 'city' ? 'flex' : ""}>
                                  {this.format(v[cityTitle])}
                                  {cityTitle == 'city' && v.cityList && v.cityList.length > 0 && (v.isScale ? <Box marginLeft='5px' borderTop="5px solid transparent"
                                    borderLeft="5px solid #434343"
                                    borderBottom="5px solid transparent"
                                  ></Box> : <Box borderBottom="5px solid #434343"
                                    borderLeft="5px solid transparent"
                                    marginLeft='5px'
                                    borderRight="5px solid transparent"
                                  ></Box>)}
                                </Box>
                              </Box>
                            );
                          })}
                      </Box>
                    );
                  }
                } else {
                  return (
                    <Box component="tr" key={index} background={v.isCity ? "#fff" : (index % 2 ? '#EBF6F8' : '#F4F9FA')} props={{
                      onClick: () => {
                        if (v.isCity) {
                          let currentList = JSON.parse(JSON.stringify(v.cityList))
                          let currentCityList = CityList
                          if (v.isScale) {
                            currentList.unshift(index + 1, 0)
                            currentCityList[index].isScale = false
                            Array.prototype.splice.apply(currentCityList, currentList);
                          } else {
                            currentCityList[index].isScale = true
                            let delCount = currentList.length
                            currentCityList.splice(index + 1, delCount)

                          }
                          this.setState({
                            CityList: currentCityList
                          })
                        }

                      }
                    }
                    }>
                      {//生成表格头部
                        CityIndex.map((cityTitle, index) => {
                          return (
                            <Box
                              textAlign="center"
                              key={index}
                              width={"20%"}
                              borderRadius="2px"
                              padding="10px 0"
                              fontSize="11px"
                              fontWeight={500}
                              borderBottom={v.isCity ? '1px solid #cfcfcf' : ""}
                              color={'#434343'}
                              component="td">
                              <Box
                                justifyContent='center'
                                alignItems='center'
                                margin={cityTitle == 'city' ? 'auto' : ""}
                                display={cityTitle == 'city' ? 'flex' : ""}>
                                {this.format(v[cityTitle])}
                                {cityTitle == 'city' && v.cityList && v.cityList.length > 0 && (v.isScale ? <Box borderTop="5px solid transparent"
                                  borderLeft="5px solid #434343"
                                  marginLeft='5px'
                                  borderBottom="5px solid transparent"
                                ></Box> : <Box borderBottom="5px solid #434343"
                                  borderLeft="5px solid transparent"
                                  marginLeft='5px'
                                  borderRight="5px solid transparent"
                                ></Box>)}
                              </Box>
                            </Box>
                          );
                        })}
                    </Box>
                  );
                }
              })}
            </tbody>
          </table>
          {isScale && (
            <Box position="relative">
              <Box
                fontWeight={600}
                height="50px"
                bottom="0"
                position="absolute"
                background="linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%)"
                width="100%"
                display="flex"
                padding="9px"
                alignItems="center"
                justifyContent="center"
                props={{
                  onClick: () => {
                    sensorsGA({
                      category: 'Coronavirus',
                      action: 'moreCity',
                      label: isInSnaptube + ''
                    });
                    this.setState({
                      isScale: false
                    });
                  }
                }}>
                <Box
                  color="#fff"
                  fontSize="18px"
                  paddingRight="5px"
                  textDecoration="none">
                  {CityBlock.more}
                </Box>

                <Box
                  borderTop="6px solid #FFF"
                  borderLeft="6px solid transparent"
                  borderRight="6px solid transparent"
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

export default CityBlock;
