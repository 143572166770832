import * as React from 'react';
import {Box} from 'jsxstyle';
import {sensorsGA} from '../../../utils';
import PropTypes from 'prop-types';
import queryString from 'query-string';

class Download extends React.Component {
  static propTypes = {
    location: PropTypes.any
  };
  constructor(props) {
    super(props);
    this.state = {
      kol: ''
    };
  }

  componentDidMount() {
    //控制列表不超过图片
    const {utm_content} = queryString.parse(this.props.location.search);
    if (utm_content) {
      this.setState({
        kol: utm_content
      });
    }
  }

  componentWillUnmount() {}

  render() {
    let {kol} = this.state;
    return (
      <Box
        minWidth="100vw"
        minHeight="100vh"
        position="absolute"
        top="0"
        backgroundColor="#264c4f">
        <Box
          component="img"
          width="100%"
          props={{src: require('../asset/download_bg.png')}}
        />
        <Box position="absolute" bottom="7%">
          <Box
            props={{
              onClick: () => {
                sensorsGA({
                  category: 'DescargaSnaptube',
                  action: `Web_KOL_Download`,
                  label: `${kol}`
                });
                window.location.href =
                  'http://dl-master.snaptube.app/installer/snaptube/latest/Click_me_to_install_SnapTube_tube_incentive_tt.apk';
              }
            }}>
            <Box
              width="100%"
              position="relative"
              textAlign="center"
              margin="0 auto">
              <Box
                component="img"
                width="80%"
                props={{
                  src: require('../asset/download_btn.png')
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default Download;
