import * as React from 'react';
import {Box} from 'jsxstyle';
import PropTypes from 'prop-types';

class LocalBlock extends React.Component {
  static propTypes = {};
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  //千分位处理
  format = (num, op) => {
    let {region} = this.props;
    let number = num || 0;
    let matchText = '$&,';
    if (region == 'BR') {
      matchText = '$&.';
    }
    if (op) {
      if (number >= 0) {
        number = '+' + number;
      }
    }
    let reg = /\d{1,3}(?=(\d{3})+$)/g;
    return (number + '').replace(reg, matchText);
  };

  render() {
    let {
      dataParams = {},
      Local = {},
      DataColor = {},
      DataBackgroundColor = {},
      isLeft = true,
      isShowHeal = true
    } = this.props;

    return (
      <Box background="#FFF" display="flex" overflowX='scroll'>
        <Box
          background={DataBackgroundColor.TotalConfirmed}
          width="25%"
          textAlign="center"
          flex={1}
          padding="5px"
          borderRight="1px solid #fff">
          <Box fontSize="10px" lineHeight="16px">
            {Local.Newly}
            <Box color={DataColor.TotalConfirmed} component="span">
              {this.format(dataParams.countryNewTotal, true)}
            </Box>
          </Box>
          <Box
            fontSize="16px"
            fontWeight={900}
            lineHeight="24px"
            fontFamily="Roboto-Black,Roboto"
            color={DataColor.TotalConfirmed}>
            {this.format(dataParams.countryTotal)}
          </Box>
          <Box fontSize="10px">{Local.TotalConfirmed}</Box>
        </Box>
        {!isLeft && isShowHeal == 'isFalse' ? (
          ''
        ) : (
          <Box
            background={DataBackgroundColor.PresentConfirmed}
            width="25%"
            textAlign="center"
            flex={1}
            padding=" 5px"
            borderRight="1px solid #fff">
            <Box fontSize="10px" lineHeight="16px">
              {Local.Newly}
              <Box color={DataColor.PresentConfirmed} component="span">
                {this.format(dataParams.countryNewPresent, true)}
              </Box>
            </Box>
            <Box
              fontSize="16px"
              fontWeight={900}
              lineHeight="24px"
              fontFamily="Roboto-Black,Roboto"
              color={DataColor.PresentConfirmed}>
              {this.format(dataParams.countryPresent)}
            </Box>
            <Box fontSize="10px">{Local.PresentConfirmed}</Box>
          </Box>
        )}

        {isLeft && isShowHeal == 'isFalse' ? (
          ''
        ) : (
          <Box
            background={DataBackgroundColor.Recovered}
            width="25%"
            textAlign="center"
            flex={1}
            padding="5px"
            borderRight="1px solid #fff">
            <Box fontSize="10px" lineHeight="16px">
              {Local.Newly}
              <Box color={DataColor.Recovered} component="span">
                {this.format(dataParams.countryNewHeal, true)}
              </Box>
            </Box>
            <Box
              fontSize="16px"
              fontWeight={900}
              lineHeight="24px"
              fontFamily="Roboto-Black,Roboto"
              color={DataColor.Recovered}>
              {this.format(dataParams.countryHeal)}
            </Box>
            <Box fontSize="10px">{Local.Recovered}</Box>
          </Box>
        )}

        <Box
          textAlign="center"
          padding="5px"
          flex={1}
          width="25%"
          background={DataBackgroundColor.Deaths}>
          <Box fontSize="10px" lineHeight="16px">
            {Local.Newly}
            <Box color={DataColor.Deaths} component="span">
              {this.format(dataParams.countryNewDead, true)}
            </Box>
          </Box>
          <Box
            fontSize="16px"
            fontWeight={900}
            lineHeight="24px"
            fontFamily="Roboto-Black,Roboto"
            color={DataColor.Deaths}>
            {this.format(dataParams.countryDead)}
          </Box>
          <Box fontSize="10px">{Local.Deaths}</Box>
        </Box>
      </Box>
    );
  }
}

export default LocalBlock;
