import 'core-js'
import 'isomorphic-fetch'
import React, { Component } from 'react'
import { hydrate } from 'react-dom'
// import Raven from 'raven-js';
import { Col } from 'jsxstyle'
// import {parse} from 'query-string';
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import { withRouter } from 'react-router'

import './reset.css'

// const isProd = process.env.NODE_ENV === 'production';

// if (isProd) {
//   Raven.config('https://3bbb1f7141894dd4b89b39dfaa77a30e@sentry.io/205346', {
//     debug: process.env.NODE_ENV === 'development',
//     release: __webpack_hash__,
//     serverName: parse(window.location.search).u,
//     environment: process.env.NODE_ENV,
//     ignoreErrors: [
//       'AutoBridge',
//       'fb_xd_fragment',
//       /Cannot call method 'exec' of undefined/
//     ],
//     whitelistUrls: [/static\.snaptube\.in/]
//   }).install();
// }

// import Auth from './Auth'
// import withGA from './withGA';
import { withBundle } from './Bundle'

import loadLarkGuide from 'bundle-loader?lazy!./LarkGuide'
import loadLarkParty from 'bundle-loader?lazy!./LarkParty'

import loadZapeeCoronavirus from 'bundle-loader?lazy!./Zapee/Coronavirus'
import loadLarkPlayerCoronavirus from 'bundle-loader?lazy!./LarkPlayer/Coronavirus'
import Coronavirus from './Snaptube/Coronavirus/'
import loadSales from 'bundle-loader?lazy!./Snaptube/Sales/'
import loadVideoShare from 'bundle-loader?lazy!./Snaptube/VideoShare/'
import loadPlus from 'bundle-loader?lazy!./Snaptube/Plus/'
import loadSnappea from 'bundle-loader?lazy!./Snappea/Propaganda/'
import loadMusicGuess from 'bundle-loader?lazy!./LarkPlayer/MusicGuess/'
import loadSEsoct from 'bundle-loader?lazy!./Spanish/SimpleEsoct/'
import loadEsoct from 'bundle-loader?lazy!./Spanish/Esoct/'
import loadAroct from 'bundle-loader?lazy!./Spanish/Aroct/'
import loadSAroct from 'bundle-loader?lazy!./Spanish/SimpleAroct/'
import loadHEsoct from 'bundle-loader?lazy!./Spanish/HappyEsoct/'
import loadSPtoct from 'bundle-loader?lazy!./Spanish/SimplePtoct/'
import loadGuideOct from 'bundle-loader?lazy!./Spanish/GuideOct/'
import loadMothersDay2021 from 'bundle-loader?lazy!./MothersDay/2021/'
import loadMusicRank from 'bundle-loader?lazy!./MusicRank/'
import loadRamadan2021 from 'bundle-loader?lazy!./Ramadan/2021/'
import loadMothersDay2021es from 'bundle-loader?lazy!./MothersDay/2021es/'
import loadVideoPublish from 'bundle-loader?lazy!./VideoPublish/'

const LarkGuide = withBundle(loadLarkGuide)
const LarkParty = withBundle(loadLarkParty)
const ZapeeCoronavirus = withBundle(loadZapeeCoronavirus)
const LarkPlayerCoronavirus = withBundle(loadLarkPlayerCoronavirus)
const Sales = withBundle(loadSales)
const VideoShare = withBundle(loadVideoShare)
const Snappea = withBundle(loadSnappea)
const MusicGuess = withBundle(loadMusicGuess)
const Plus = withBundle(loadPlus)
const SEsoct = withBundle(loadSEsoct)
const HEsoct = withBundle(loadHEsoct)
const Esoct = withBundle(loadEsoct)
const Aroct = withBundle(loadAroct)
const SAroct = withBundle(loadSAroct)
const SPtoct = withBundle(loadSPtoct)
const Guidetoct = withBundle(loadGuideOct)
const MothersDay2021 = withBundle(loadMothersDay2021)
const MusicRank = withBundle(loadMusicRank)
const Ramadan2021 = withBundle(loadRamadan2021)
const MothersDay2021es = withBundle(loadMothersDay2021es)
const VideoPublish = withBundle(loadVideoPublish)

class MyApp extends Component {
  componentDidMount() {
    const sensors = require('sa-sdk-javascript')
    sensors.init({
      server_url: 'https://sensors.snappea.com/sa?project=GrowthCampaigns',
      // heatmap_url: 'https://intranet.snappea.com/cjv2aqsok0001hk23x0qws6hp',
      // web_url: 'https://sensors.snappea.com/sa?project=GrowthCampaigns',
      heatmap: {
        clickmap: 'default',
        scroll_notice_map: 'not_collect'
      },
      is_track_single_page: true
    })
    sensors.quick('autoTrack')
    window._spinner && window._spinner.stop()
  }

  // componentDidCatch(error, extra) {
  //   Raven.captureException(error, {extra});
  // }

  render() {
    return (
      <Col
        minHeight="100vh"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
      >
        <Switch>
          <Route path="/larkparty" component={LarkParty} />
          <Route path="/larkguide" component={LarkGuide} />
          <Route path="/coronavirus/debug" />
          <Route path="/zapee/coronavirus" component={ZapeeCoronavirus} />
          <Route path="/musicGuess" component={MusicGuess} />
          <Route path="/larkplayer/coronavirus" component={LarkPlayerCoronavirus} />
          <Route
            path="/coronavirus"
            // eslint-disable-next-line no-unused-vars
            render={props => {
              // eslint-disable-next-line react/prop-types
              return <Coronavirus myContext={this.props.context}></Coronavirus>
            }}
          />
          <Route path="/sales" component={Sales} />
          <Route path="/snappea" component={Snappea} />
          <Route path="/videoShare" component={VideoShare} />
          <Route path="/plus" component={Plus} />
          <Route path="/SimpleEsoct" component={SEsoct} />
          <Route path="/HappyEsoct" component={HEsoct} />
          <Route path="/SimplePtoct" component={SPtoct} />
          <Route path="/SimpleAroct" component={SAroct} />
          <Route path="/esoct" component={Esoct} />
          <Route path="/aroct" component={Aroct} />
          <Route path="/guide" component={Guidetoct} />
          <Route path="/mothers-day-2021" component={MothersDay2021} />
          <Route path="/music-rank" component={MusicRank} />
          <Route path="/ramadan-2021" component={Ramadan2021} />
          <Route path="/mothers-day-2021es" component={MothersDay2021es} />
          <Route path="/video-publish" component={VideoPublish} />
        </Switch>
      </Col>
    )
  }
}

// const Analysed = typeof window !== 'undefined' ? withGA(MyApp) : <MyApp />;

const App = withRouter(MyApp)

export { App }

typeof window !== 'undefined' &&
  hydrate(
    <BrowserRouter>
      <MyApp />
    </BrowserRouter>,
    document.getElementById('root')
  )
