import * as React from 'react';
import ReactEcharts from 'echarts-for-react';
import { Box } from 'jsxstyle';
import PropTypes from 'prop-types';
import { sensorsGA } from '../../../utils';
import queryString from 'query-string';
import Share from '../../../Share';
import countryJson from '../json/country.json';
import LocalBlock from './Modal/LocalBlock';
import WorldBlock from './Modal/WorldBlock';
import VideoListBlock from './Modal/VideoBlock';
import CityModalBlock from './Modal/CityBlock';
import CountryModalBlock from './Modal/CountryBlock';



const TabItem = ({ title, onClick, clicked, bgColor }) => {
  return (
    <Box
      flex={1}
      display="flex"
      justifyContent="center"
      zIndex={9}
      background={clicked ? '#fff' : bgColor}
      alignItems="center"
      props={{
        onClick
      }}>
      <Box>
        <Box
          fontSize="14px"
          fontFamily="Roboto-Black,Roboto"
          fontWeight={900}
          color={clicked ? '#006280' : '#E8F9FF'}>
          {title}
        </Box>
        {clicked && <Box borderTop="2px solid #45BADD" />}
      </Box>
    </Box>
  );
};

const ButtonItem = ({ title, onClick, clicked }) => {
  return (
    <Box
      margin="5px"
      flex={1}
      display="flex"
      justifyContent="center"
      borderRadius="6px"
      border="1px solid #76D3EE"
      background={clicked ? 'rgba(239,251,255,1)' : '#fff'}
      alignItems="center"
      props={{
        onClick
      }}>
      <Box
        textAlign="center"
        padding="5px 0"
        fontSize="10px"
        fontFamily="Roboto-Black,Roboto"
        fontWeight={900}
        color={clicked ? '#006280' : '#333333'}>
        {title}
      </Box>
    </Box>
  );
};

const timeoffsetList = countryJson.timeoffsetList;
const countryNameList = countryJson.countryNameList;
const rarList = countryJson.rarList;
const renList = countryJson.renList;
const resList = countryJson.resList;
const rptList = countryJson.rptList;
const leftList = countryJson.leftList;
const nameList = countryJson.nameList;

class NewDetail extends React.Component {
  static propTypes = {
    location: PropTypes.any,
    title: PropTypes.any,
    onClick: PropTypes.any,
    clicked: PropTypes.any,
    bgColor: PropTypes.any,
    isInSnaptube: PropTypes.any
  };
  constructor(props) {
    super(props);
    this.state = {

      myConfig: {},
      myData: {},
      myHistory: {},
      tableColor: {},
      dataParams: {},
      isInSnaptube: false,
      myList: [],
      areaList: [],
      currentLocalTitle: [],
      currentWorldTitle: [],

      area: 'RAR',
      region: 'EG',
      isLeft: false,
      showFloatButton: false,
      currentType: 'local',
      tabStyle: 'relative',
      worldMapConfirm: true,
      worldConfirm: true,
      localAdd: true,
      isModalShow: false,

      summaryHistory: [],
      WorldWide: {},
      worldOption: {},
      updateTime: '',
      isScale: true,
      localClick: 'newCases',
      worldClick: 'newCases',
      isPush: false,
      currentVideoInfo: {},

    };
  }

  initData = (props) => {
    let { ssrData, isLeft, region, isInSnaptube, area } = props

    let windowData = ssrData
    let item = windowData && windowData.Item;
    if (item) {
      let myConfig = item.config;
      let myData = item.foreignData;
      let currentLocalTitle = myConfig.Local.newCaseTitle
      let currentWorldTitle = myConfig.WorldWide.newCaseTitle
      //处理左右阅读习惯
      let dataParams = {};
      if (isLeft) {
        dataParams = {
          updateTime: this.dealCountryTime(myData.countryData.date, region, isLeft),
          countryTotal: myData.countryData.total,
          countryPresent: myData.countryData.present,
          countryHeal: myData.countryData.heal,
          countryDead: myData.countryData.dead,
          countryNewTotal: myData.countryData.newTotal,
          countryNewPresent: myData.countryData.newPresent,
          countryNewHeal: myData.countryData.newHeal,
          countryNewDead: myData.countryData.newDead,
          worldTotal: myData.worldData.worldTotal,
          worldPresent: myData.worldData.worldPresent,
          worldHeal: myData.worldData.worldHeal,
          worldDead: myData.worldData.worldDead,
          worldNewTotal: myData.worldData.worldNewTotal,
          worldNewPresent: myData.worldData.worldNewPresent,
          worldNewHeal: myData.worldData.worldNewHeal,
          worldNewDead: myData.worldData.worldNewDead
        };
      } else {
        dataParams = {
          updateTime: this.dealCountryTime(myData.countryData.date, region, isLeft),
          countryTotal: myData.countryData.dead,
          countryPresent: myData.countryData.heal,
          countryHeal: myData.countryData.present,
          countryDead: myData.countryData.total,
          countryNewTotal: myData.countryData.newDead,
          countryNewPresent: myData.countryData.newHeal,
          countryNewHeal: myData.countryData.newPresent,
          countryNewDead: myData.countryData.newTotal,
          worldTotal: myData.worldData.worldDead,
          worldPresent: myData.worldData.worldHeal,
          worldHeal: myData.worldData.worldPresent,
          worldDead: myData.worldData.worldTotal,
          worldNewTotal: myData.worldData.worldNewDead,
          worldNewPresent: myData.worldData.worldNewHeal,
          worldNewHeal: myData.worldData.worldNewPresent,
          worldNewDead: myData.worldData.worldNewTotal
        };
      }

      this.setState({
        myConfig,
        myData,
        dataParams,
        isLeft,
        area,
        region,
        isInSnaptube,
        currentLocalTitle,
        currentWorldTitle
      });
    }
  }


  componentWillMount() {
    let { props } = this
    this.initData(props)
  }
  componentDidMount() {
    const self = this;
    var intersectionObserver = new IntersectionObserver(function (entries) {
      // If intersectionRatio is 0, the target is out of view
      // and we do not need to do anything.\
      if (entries[0].target.id == 'backPic') {
        if (entries[0].intersectionRatio > 0) {
          self.setState({
            tabStyle: 'relative'
          });
        } else {
          self.setState({
            tabStyle: 'fixed'
          });
        }
      } else if (entries[0].target.id == 'local') {
        if (entries[0].intersectionRatio > 0) {
          self.setState({
            currentType: 'local'
          });
        }
      } else if (entries[0].target.id == 'worldWide') {
        if (entries[0].intersectionRatio > 0) {
          self.setState({
            currentType: 'world'
          });
        }

      } else if (entries[0].target.id == 'prevention') {
        if (entries[0].intersectionRatio > 0) {
          self.setState({
            currentType: 'prevention'
          });
        }
      } else if (entries[0].target.id == 'email') {

        if (entries[0].intersectionRatio <= 0) {
          self.setState({
            showFloatButton: true
          });
        } else {
          self.setState({
            showFloatButton: false
          });
        }
      }
    });
    // start observing
    intersectionObserver.observe(document.querySelector('#email'));
    intersectionObserver.observe(document.querySelector('#backPic'));
    intersectionObserver.observe(document.querySelector('#local'));
    intersectionObserver.observe(document.querySelector('#worldWide'));
    intersectionObserver.observe(document.querySelector('#prevention'));
    //滑动节流
    // const debounce = (fn, wait) => {
    //   const self = this;
    //   let timeout = null;
    //   return function() {
    //     if (!timeout) {
    //       timeout = setTimeout(() => {
    //         timeout = null;
    //         fn(self);
    //       }, wait);
    //     }
    //   };
    // };
    //控制列表不超过图片
    // function pageScroll(self) {
    //   t1 = document.documentElement.scrollTop || document.body.scrollTop;
    //   t2 = document.getElementById('backPic').offsetHeight;

    //   const {VideoBlock = {}} = self.state.myConfig;
    //   const hasThirdPart = VideoBlock.count > 0;
    //   t3 = document.getElementById('local').offsetTop;
    //   t4 = document.getElementById('worldWide').offsetTop;
    //   t5 = document.getElementById('prevention').offsetTop;
    //   //自动切换tab
    //   if (Math.abs(t1 - t3) < 200) {
    //     self.setState({
    //       currentType: 'local'
    //     });
    //   } else if (Math.abs(t1 - t4) < 200) {
    //     self.setState({
    //       currentType: 'world'
    //     });
    //   } else if (hasThirdPart) {
    //     if (Math.abs(t1 - t5) < 200) {
    //       self.setState({
    //         currentType: 'prevention'
    //       });
    //     }
    //   }

    //   if (t1 < 50) {
    //     self.setState({
    //       showFloatButton: false
    //     });
    //   } else {
    //     self.setState({
    //       showFloatButton: true
    //     });
    //   }

    //   if (t1 > t2 - 5) {
    //     self.setState({
    //       tabStyle: 'fixed'
    //     });
    //   } else {
    //     self.setState({
    //       tabStyle: 'relative'
    //     });
    //   }
    // }
    const { isInSnaptube } = this.props;
    let { region = 'EG', anchor = 'local' } = queryString.parse(
      this.props.location.search
    );
    if (Array.isArray(region)) {
      region = region[0];
    }
    let area = region;
    //check country
    if (resList.includes(region)) {
      area = 'RES';
    } else if (rarList.includes(region)) {
      area = 'RAR';
    } else if (renList.includes(region)) {
      area = 'REN';
    } else {
      area = 'RPT';
    }

    if (!leftList.includes(area)) {
      this.setState({
        isLeft: false
      });
    } else {
      this.setState({
        isLeft: true
      })
    }
    this.setState(
      {
        isInSnaptube,
        area,
        region,
        anchor
      },
      () => {
        this.getData();
      }
    );
    //记录端内外访问
    // sensorsGA({
    //   category: 'Coronavirus',
    //   action: 'pageview',
    //   label: isInSnaptube + ''
    // });
    if (anchor == 'world') {
      setTimeout(() => {
        document.getElementById('worldWide') &&
          document.getElementById('worldWide').scrollIntoView();
        this.setState({
          anchor: 'local',
          tabStyle: 'fixed',
          currentType: 'world'
        });
      }, 2000);
    }

    // window.addEventListener('scroll', debounce(pageScroll, 200), {
    //   passive: true
    // });
  }

  //千分位处理
  format = (num, op) => {
    let { region } = this.state;
    let number = num || 0;
    let matchText = '$&,';
    if (region == 'BR') {
      matchText = '$&.';
    }
    if (op) {
      if (number >= 0) {
        number = '+' + number;
      }
    }
    let reg = /\d{1,3}(?=(\d{3})+$)/g;
    return (number + '').replace(reg, matchText);
  };
  //处理不同国家的时间
  dealCountryTime = (value, ssrRegion, ssrIsLeft) => {
    let region = ssrRegion || this.state.region
    let myTime = new Date(value).valueOf();
    let timeoffset = timeoffsetList[region] || 8;
    let countryTime = myTime - timeoffset * 60 * 60 * 1000;
    return this.formatTime(new Date(countryTime), ssrIsLeft);
  };
  formateTimeStr(num) {
    return num < 10 ? `0${num}` : num + '';
  }
  //格式化时间
  formatTime = (value, ssrIsLeft) => {
    let isLeft = typeof (ssrIsLeft) == 'undefined' ? this.state.isLeft : ssrIsLeft
    let year = value.getFullYear();
    let month = this.formateTimeStr(value.getMonth() + 1);
    let day = this.formateTimeStr(value.getDate());
    let hours = this.formateTimeStr(value.getHours());
    let min = this.formateTimeStr(value.getMinutes());
    if (!isLeft) {
      return `${hours}:${min} ${day}/${month}/${year}`;
    }
    return `${day}/${month}/${year} ${hours}:${min}`;
  };

  //处理国家rank
  dealWithCountry = (countryRank, ssrRegion, ssrArea) => {

    let region = ssrRegion || this.state.region
    let area = ssrArea || this.state.area


    let chineseName = nameList[region];
    let areaList = []

    let areaTotal = 0
    let areaHeal = 0
    let areaDead = 0
    let areaAdd = 0
    let curArea = 0
    let relationCountry = []


    //处理国家层级
    if (area == 'REN') {
      areaList.push({ "country": 'South-East Asia Region' })
    } else if (area == 'RAR') {
      areaList.push({ "country": 'الشرق الأوسط وشمال أفريقيا' })
    } else if (area == 'RES') {
      areaList.push({ "country": 'Región de las Américas' })
    } else if (area == 'RPT') {
      areaList.push({ "country": 'Região das Américas' })
    }

    countryRank = countryRank.map(v => {
      let i = 0
      for (; i < areaList.length; i++) {
        if (areaList[i].country == v[`${area}Area`]) {
          break;
        }
      }
      if (i == areaList.length) {
        areaList.push({ "country": v[`${area}Area`] })
      }
      curArea = i
      areaTotal = areaList[curArea].total || 0
      areaHeal = areaList[curArea].heal || 0
      areaDead = areaList[curArea].dead || 0
      areaAdd = areaList[curArea].add || 0
      relationCountry = areaList[curArea].countryList || []
      areaTotal += Number(v.confirm)
      areaHeal += Number(v.heal)
      areaDead += Number(v.dead)
      areaAdd += Number(v.confirmAdd)


      if (chineseName != v.name) {
        relationCountry.push({
          country: v[`${area}Name`],
          total: v.confirm,
          heal: v.heal,
          dead: v.dead,
          add: v.confirmAdd
        });
      }


      areaList[curArea] = {
        country: v[`${area}Area`],
        total: areaTotal,
        heal: areaHeal,
        dead: areaDead,
        add: areaAdd,
        countryList: relationCountry
      }

    });

    return areaList
  }

  //处理城市层级 
  dealWithCity = (CityList) => {

    let myList = []

    let cityTotal = 0
    let cityHeal = 0
    let cityDead = 0
    let cityPresent = 0
    let curCity = 0
    let relationCity = []

    CityList.map(v => {
      let i = 0
      for (; i < myList.length; i++) {
        if (myList[i].city == v['city']) {
          break;
        }

      }
      if (i == myList.length) {
        myList.push({ "city": v['city'] })
      }
      curCity = i
      cityTotal = myList[curCity].total || 0
      cityHeal = myList[curCity].heal || 0
      cityDead = myList[curCity].dead || 0
      cityPresent = myList[curCity].present || 0
      relationCity = myList[curCity].cityList || []
      cityTotal += Number(v.total)
      cityHeal += Number(v.heal)
      cityDead += Number(v.dead)
      cityPresent += Number(v.present)
      if (v.relation !== 'none') {
        relationCity.push({
          city: v['relation'],
          total: v.total,
          heal: v.heal,
          dead: v.dead,
          present: v.present
        });
      }

      myList[curCity] = {
        city: v['city'],
        isCity: true,
        isScale: true,
        total: cityTotal,
        heal: cityHeal,
        dead: cityDead,
        present: cityPresent,
        cityList: relationCity
      }
    })

    return myList


  }

  getData = async () => {
    let { isLeft, region, area } = this.state;
    let windowData = window.__Data__ || {}
    if (!windowData.Item) {
      let url = `https://me0xn4hy3i.execute-api.us-east-1.amazonaws.com/staging/api/coronavirus/newGet?lang=${region}`;
      await fetch(url, {
        method: 'get',
        headers: {}
      })
        .then(response => response.json())
        .then(res => {
          if (res.data) {
            windowData = res.data
          }
        });
    }



    let item = (windowData && windowData.Item);
    let myConfig = item.config;
    let myData = item.foreignData;
    let myHistory = item.history;
    let countryRank = item.countryRank;

    let CityList = myConfig.CityList
    let tableColor = [];
    myConfig.CityColorIndex.map(v => {
      let newColor = v.substr(0, v.length - 1) + ',0.3)';
      tableColor.push(newColor);
    });


    let myList = this.dealWithCity(CityList)
    let areaList = this.dealWithCountry(countryRank)
    //处理左右阅读习惯
    let dataParams = {};
    if (isLeft) {
      dataParams = {
        updateTime: this.dealCountryTime(myData.countryData.date),
        countryTotal: myData.countryData.total,
        countryPresent: myData.countryData.present,
        countryHeal: myData.countryData.heal,
        countryDead: myData.countryData.dead,
        countryNewTotal: myData.countryData.newTotal,
        countryNewPresent: myData.countryData.newPresent,
        countryNewHeal: myData.countryData.newHeal,
        countryNewDead: myData.countryData.newDead,
        worldTotal: myData.worldData.worldTotal,
        worldPresent: myData.worldData.worldPresent,
        worldHeal: myData.worldData.worldHeal,
        worldDead: myData.worldData.worldDead,
        worldNewTotal: myData.worldData.worldNewTotal,
        worldNewPresent: myData.worldData.worldNewPresent,
        worldNewHeal: myData.worldData.worldNewHeal,
        worldNewDead: myData.worldData.worldNewDead
      };
    } else {
      dataParams = {
        updateTime: this.dealCountryTime(myData.countryData.date),
        countryTotal: myData.countryData.dead,
        countryPresent: myData.countryData.heal,
        countryHeal: myData.countryData.present,
        countryDead: myData.countryData.total,
        countryNewTotal: myData.countryData.newDead,
        countryNewPresent: myData.countryData.newHeal,
        countryNewHeal: myData.countryData.newPresent,
        countryNewDead: myData.countryData.newTotal,
        worldTotal: myData.worldData.worldDead,
        worldPresent: myData.worldData.worldHeal,
        worldHeal: myData.worldData.worldPresent,
        worldDead: myData.worldData.worldTotal,
        worldNewTotal: myData.worldData.worldNewDead,
        worldNewPresent: myData.worldData.worldNewHeal,
        worldNewHeal: myData.worldData.worldNewPresent,
        worldNewDead: myData.worldData.worldNewTotal
      };
    }
    this.setState(
      {
        myConfig,
        myData,
        myHistory,
        tableColor,
        dataParams,
        currentLocalTitle: myConfig.Local.newCaseTitle,
        currentWorldTitle: myConfig.WorldWide.newCaseTitle,
        myList,
        areaList
      },
      () => {
        this.getLocalOption();
        this.getWorldOption();
      }
    );
  };

  //获取本地折线图
  getLocalOption = () => {
    let { myHistory = [], isLeft, myConfig } = this.state;
    let { Local = {} } = myConfig;
    let history = myHistory.countryHistory;
    let dateList = [];
    let confirmList = [];
    let deathList = [];
    let healList = [];

    let modifyConfirmList = [];

    let TotalConfirmed = Local.TotalConfirmed;
    let Deaths = Local.Deaths;
    let Recovered = Local.Recovered;
    if (!isLeft) {
      history.reverse();
      TotalConfirmed = Local.Deaths;
      Recovered = Local.PresentConfirmed;
      Deaths = Local.TotalConfirmed;
    }
    history.map(v => {
      dateList.push(v.date);
      confirmList.push(v.confirm);
      deathList.push(v.dead);
      healList.push(v.heal);
      modifyConfirmList.push(v.modifyConfirm > 0 ? v.modifyConfirm : 0);
    });

    // config
    let xAxis = {
      type: 'category',
      data: dateList
    };
    let tooltip = {
      trigger: 'axis'
    };
    let grid = {
      top: '15%',
      left: '3%',
      right: '4%',
      bottom: '3%',

      containLabel: true
    };
    let yAxis = {
      axisLine: { show: false },
      axisTick: { show: false },
      type: 'value'
    };

    // single config
    var modifyConfirmOption = {
      xAxis,
      tooltip,
      grid,
      yAxis,
      series: [
        {
          symbol: 'none',
          name: Local.NewCases,
          data: modifyConfirmList,
          color: '#B10C0C',
          type: 'line'
        }
      ]
    };

    var option = {
      xAxis,
      tooltip,
      grid,
      yAxis,
      series: [
        {
          symbol: 'none',
          name: TotalConfirmed,
          data: confirmList,
          color: '#FFA200',
          type: 'line'
        }
      ]
    };

    var deathOption = {
      xAxis,
      tooltip,
      grid,
      yAxis,
      series: [
        {
          symbol: 'none',
          name: Deaths,
          data: deathList,
          color: '#434343',
          type: 'line'
        }
      ]
    };
    var recoverOption = {
      xAxis,
      tooltip,
      grid,
      yAxis,
      series: [
        {
          symbol: 'none',
          name: Recovered,
          data: healList,
          color: '#009A41',
          type: 'line'
        }
      ]
    };
    this.setState({
      currentLocalOption: modifyConfirmOption,
      localOption: option,
      localAddOption: modifyConfirmOption,
      localRecoverOption: recoverOption,
      localDeathOption: deathOption
    });
  };

  //获取世界折线图
  getWorldOption = () => {
    let { myHistory = [], myConfig, isLeft } = this.state;
    let { WorldWide = {} } = myConfig;
    let history = myHistory.worldHistory;
    let dateList = [];
    let confirmList = [];
    let deathList = [];
    let healList = [];
    let modifyConfirmList = [];

    let TotalConfirmed = WorldWide.TotalConfirmed;
    let Deaths = WorldWide.Deaths;
    let Recovered = WorldWide.Recovered;

    // let curSummaryHistory =

    if (!isLeft) {
      history.reverse();
      TotalConfirmed = WorldWide.Deaths;
      Recovered = WorldWide.PresentConfirmed;
      Deaths = WorldWide.TotalConfirmed;
    }
    history.map(v => {
      healList.push(v.heal);
      dateList.push(v.date);
      confirmList.push(v.confirm);
      deathList.push(v.dead);
      modifyConfirmList.push(v.modifyConfirm);
    });

    // config
    let xAxis = {
      type: 'category',
      data: dateList
    };
    let tooltip = {
      trigger: 'axis'
    };
    let grid = {
      top: '15%',
      left: '3%',
      right: '4%',
      bottom: '3%',

      containLabel: true
    };
    let yAxis = {
      axisLine: { show: false },
      axisTick: { show: false },
      type: 'value'
    };

    var modifyConfirmOption = {
      xAxis,
      tooltip,
      grid,
      yAxis,
      series: [
        {
          symbol: 'none',
          name: WorldWide.NewCases,
          data: modifyConfirmList,
          color: '#B10C0C',
          type: 'line'
        }
      ]
    };

    var option = {
      xAxis,
      tooltip,
      grid,
      yAxis,
      series: [
        {
          symbol: 'none',
          name: TotalConfirmed,
          data: confirmList,
          color: '#FFA200',
          type: 'line'
        }
      ]
    };

    var recoverOption = {
      xAxis,
      tooltip,
      grid,
      yAxis,
      series: [
        {
          symbol: 'none',
          name: Recovered,
          data: healList,
          color: '#009A41',
          type: 'line'
        }
      ]
    };

    var deathOption = {
      xAxis,
      tooltip,
      grid,
      yAxis,
      series: [
        {
          symbol: 'none',
          name: Deaths,
          data: deathList,
          color: '#333333',
          type: 'line'
        }
      ]
    };
    this.setState({
      currentWorldOption: modifyConfirmOption,
      worldOption: option,
      worldAddOption: modifyConfirmOption,
      worldRecoverOption: recoverOption,
      worldDeathOption: deathOption
    });
  };

  //渲染 折线图
  renderCharts = option => {
    return (
      <Box width="100%" minHeight="200px" backgroundColor="#fff">
        <ReactEcharts
          style={{ height: '50vw' }}
          option={option}
          notMerge={true}
          lazyUpdate={true}
          theme={'theme_name'}
        />
      </Box>
    );
  };

  //渲染tab Bar
  renderTabBar = () => {
    const { TabBlock = {}, VideoBlock = {} } = this.state.myConfig;

    const { currentType, isInSnaptube, region } = this.state;
    //判断是否存在内容模块
    let hasThirdPart = true;
    if (!(VideoBlock.count > 0)) {
      hasThirdPart = false;
    }
    let FirstTab = (
      <TabItem
        bgColor="#45BADD"
        title={countryNameList[region]}
        clicked={currentType === 'local'}
        onClick={() => {
          this.setState({
            currentType: 'local'
          });
          sensorsGA({
            category: 'Coronavirus',
            action: 'LocalTab',
            label: isInSnaptube + ''
          });
          document.getElementById('local').scrollIntoView();
        }}
      />
    );
    let SecondTab = (
      <TabItem
        bgColor="#76D3EE"
        title={TabBlock.WorldWide}
        clicked={currentType === 'world'}
        onClick={() => {
          this.setState({
            currentType: 'world',
            tabStyle: 'fixed'
          });
          sensorsGA({
            category: 'Coronavirus',
            action: 'WorldTab',
            label: isInSnaptube + ''
          });
          document.getElementById('worldWide').scrollIntoView();
        }}
      />
    );
    let ThirdTab = (
      <TabItem
        bgColor="#45BADD"
        title={TabBlock.Prevention}
        clicked={currentType === 'prevention'}
        onClick={() => {
          this.setState({
            currentType: 'prevention',
            tabStyle: 'fixed'
          });
          sensorsGA({
            category: 'Coronavirus',
            action: 'PreventionTab',
            label: isInSnaptube + ''
          });
          document.getElementById('prevention').scrollIntoView();
        }}
      />
    );
    return (
      <Box display="flex" height="10vw">
        {FirstTab}
        {SecondTab}
        {hasThirdPart && ThirdTab}
      </Box>
    );
  };

  render() {
    let {
      BackgroundBlock = {},
      Local = {},
      WorldWide = {},
      BackgroundColor = {},
      DataColor = {},
      FeedBackBlock = {},
      ShareBlock = {},
      CityBlock = {},
      CityList = [],
      CityIndex = [],
      CityColorIndex = [],
      CountryBlock = [],
      CountryIndex = [],
      CountryColorIndex = [],
      VideoBlock = {},
      VideoList = [],
      ModuleList = []
    } = this.state.myConfig;
    let {
      tabStyle,
      isInSnaptube,
      area,
      region,
      isLeft,
      isModalShow,
      tableColor,
      dataParams = {},
      localClick,
      worldClick,
      myList,
      areaList,
      currentLocalTitle,
      currentWorldTitle
    } = this.state;

    let {
      currentLocalOption = {},
      currentWorldOption = {},
      localRecoverOption = {},
      localDeathOption = {},
      localOption = {},
      localAddOption = {},
      worldOption = {},
      worldAddOption = {},
      worldDeathOption = {},
      worldRecoverOption
    } = this.state;

    let shareDes = ShareBlock.ShareDes;
    return (
      <Box
        width="100%"
        minWidth="100vw"
        minHeight="100vh"
        position="absolute"
        top="0"
        paddingBottom="5vh"
        background="#FFF">
        {/* 弹出框 */}
        {isModalShow && (
          <Box
            width="100%"
            height="100vh"
            position="fixed"
            top={0}
            left={0}
            zIndex={9999}>
            <Box
              width="100vw"
              height="100vh"
              background="rgba(0,0,0,0.5)"
              position="absolute"
              zIndex={9}
              props={{
                onClick: () => {
                  this.setState({
                    isModalShow: false
                  });
                }
              }}
              top={0}
            />
            <Box
              width="6vw"
              position="absolute"
              zIndex={99}
              right="5vw"
              top="5vw"
              props={{
                onClick: () => {
                  this.setState({
                    isModalShow: false
                  });
                }
              }}>
              <Box
                component="img"
                width="100%"
                props={{ src: require('../asset/icon_X.png') }}
              />
            </Box>
            <Box
              width="80%"
              margin="auto"
              textAlign={isLeft ? 'left' : 'right'}
              zIndex={9}
              position="relative"
              top="10vh">
              <Box
                component="img"
                width="100%"
                props={{
                  src: require(`../asset/pic_win.png`)
                }}
              />
              <Box
                position="relative"
                top="-5px"
                minHeight="50vh"
                background="#fff"
                padding="30px 20px 60px"
                borderRadius="0 0 20px 20px ">
                <Box
                  fontSize="18px"
                  fontWeight="bold"
                  color="#434343"
                  paddingBottom="18px">
                  {BackgroundBlock.TypicalSymptoms}
                </Box>
                <Box
                  fontSize="14px"
                  color="#434343"
                  whiteSpace="pre-line"
                  position="relative"
                  overflow="scroll"
                  maxHeight="40vh">
                  {BackgroundBlock.TypicalShow}
                </Box>
                {FeedBackBlock.feedBack && (
                  <Box
                    fontSize="12px"
                    width="100%"
                    left="0"
                    textAlign="center"
                    position="absolute"
                    bottom="20px"
                    fontWeight="bold"
                    fontStyle="italic">
                    {isLeft && (
                      <span color="#434343">{FeedBackBlock.feedBack}</span>
                    )}
                    <Box
                      color="#50C4FF"
                      padding="0 10px"
                      textDecoration="none"
                      component="a"
                      props={{
                        onClick: () => {
                          sensorsGA({
                            category: 'Coronavirus',
                            action: 'emailModalClick',
                            label: isInSnaptube + ''
                          });
                        },
                        href: FeedBackBlock.href
                      }}>
                      {FeedBackBlock.label}
                    </Box>
                    {!isLeft && (
                      <span color="#434343">{FeedBackBlock.feedBack}</span>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )}

        {/* 数据来源说明 */}
        <Box position="relative" paddingBottom='35vw'>
          <Box
            position='absolute'
            component="img"
            width="100%"
            props={{
              id: 'backPic',
              src: require(`../asset/${area}/pic_banner.png`)
            }}
          />
          <Box
            position="absolute"
            width="25vw"
            top="1vh"
            right="0"
            component="a"
            props={{
              id: 'email',
              onClick: () => {
                sensorsGA({
                  category: 'Coronavirus',
                  action: 'emailPageClick_detail',
                  label: isInSnaptube + ''
                });
              },
              href: FeedBackBlock.href
            }}>
            <Box
              component="img"
              width="100%"
              props={{
                src: require(`../asset/${area}/suggestion.png`)
              }}
            />
          </Box>
          <Box
            position="absolute"
            bottom="10%"
            width="80%"
            left="10%"
            props={{
              onClick: () => {
                sensorsGA({
                  category: 'Coronavirus',
                  action: 'datasFrom',
                  label: isInSnaptube + ''
                });
                this.setState({
                  isModalShow: true
                });
              }
            }}>
            <Box
              component="img"
              width="100%"
              props={{
                src: require(`../asset/${area}/datas.png`)
              }}
            />
          </Box>
        </Box>

        {/* 全球和本国的tab */}
        <Box
          width="100%"
          zIndex={9}
          top="-1vw"
          position={tabStyle}
          props={{
            id: 'tabBlock'
          }}>
          {this.renderTabBar()}
        </Box>
        <Box height="1vh" />
        <Box>
          {/* 本国模块 */}
          <Box
            props={{
              id: 'local'
            }}>
            <Box
              textAlign={isLeft ? 'left' : 'right'}
              padding="0 20px 20px 20px "
              position="relative"
              marginTop={tabStyle == 'fixed' ? '10vw' : ''}>
              <Box
                fontSize="27px"
                fontWeight="bold"
                color="#333"
                fontFamily="Roboto-Bold,Roboto"
                lineHeight="60px">
                {Local.DataUpdatedIn}
              </Box>
              <Box
                fontSize="11px"
                color="#333"
                fontWeight={400}
                fontFamily="Roboto-Bold,Roboto">
                {!isLeft && <span>{dataParams.updateTime}</span>}
                {Local.DataUpdateOn}
                {isLeft && <span>{dataParams.updateTime}</span>}
              </Box>
            </Box>
            <Box>
              <Box margin="0 20px" overflow="hidden">
                <LocalBlock
                  DataBackgroundColor={BackgroundColor}
                  isShowHeal={Local.isShowHealData}
                  isLeft={isLeft}
                  dataParams={dataParams}
                  Local={Local}
                  DataColor={DataColor}
                  region={region}
                />
                <Box paddingTop="20px">
                  <Box textAlign="center" padding="0 0 5px 0 ">
                    <Box
                      color="#434343"
                      fontSize="16px"
                      fontFamily="Roboto-Regular,Roboto">
                      {currentLocalTitle}
                    </Box>
                  </Box>
                  {this.renderCharts(currentLocalOption)}
                  <Box color="#B9B499" padding="0 10px 10px">
                    {Local.Diagram}
                  </Box>
                  <Box margin="10px" position="relative">
                    <Box display="flex" width="100%">
                      <ButtonItem
                        title={Local.NewCases}
                        onClick={() => {
                          this.setState({
                            localClick: 'newCases',
                            currentLocalOption: localAddOption,
                            currentLocalTitle: Local.newCaseTitle
                          });
                          sensorsGA({
                            category: 'Coronavirus',
                            action: 'LocalNewCases',
                            label: isInSnaptube + ''
                          });
                        }}
                        clicked={localClick == 'newCases'}
                      />
                      <ButtonItem
                        title={isLeft ? Local.TotalConfirmed : Local.Deaths}
                        onClick={() => {
                          this.setState({
                            localClick: 'totalConfirmed',
                            currentLocalOption: localOption,
                            currentLocalTitle: Local.totalConfirmTitle
                          });
                          sensorsGA({
                            category: 'Coronavirus',
                            action: 'LocalTotalConfirmed',
                            label: isInSnaptube + ''
                          });
                        }}
                        clicked={localClick == 'totalConfirmed'}
                      />
                      {Local.isShowHeal && Local.isShowHeal != " " && (
                        <ButtonItem
                          title={
                            isLeft ? Local.Recovered : Local.PresentConfirmed
                          }
                          onClick={() => {
                            this.setState({
                              localClick: 'heal',
                              currentLocalOption: localRecoverOption,
                              currentLocalTitle: Local.recoverTitle
                            });
                            sensorsGA({
                              category: 'Coronavirus',
                              action: 'LocalHeal',
                              label: isInSnaptube + ''
                            });
                          }}
                          clicked={localClick == 'heal'}
                        />
                      )}
                      <ButtonItem
                        title={isLeft ? Local.Deaths : Local.TotalConfirmed}
                        onClick={() => {
                          this.setState({
                            localClick: 'deaths',
                            currentLocalOption: localDeathOption,
                            currentLocalTitle: Local.deathTitle
                          });
                          sensorsGA({
                            category: 'Coronavirus',
                            action: 'LocalDeaths',
                            label: isInSnaptube + ''
                          });
                        }}
                        clicked={localClick == 'deaths'}
                      />
                    </Box>
                  </Box>
                </Box>

              </Box>
              {CityBlock.show && CityBlock.show != " " && (
                <CityModalBlock
                  region={region}
                  isInSnaptube={isInSnaptube}
                  CityBlock={CityBlock}
                  CityIndex={CityIndex}
                  CityColorIndex={CityColorIndex}
                  CityList={myList}
                  tableColor={tableColor}
                />
              )}
            </Box>
          </Box>
          {/* 全球模块  */}
          <Box
            props={{
              id: 'worldWide'
            }}>
            <Box
              padding="20px"
              position="relative"
              textAlign={isLeft ? 'left' : 'right'}>
              <Box
                fontSize="27px"
                fontWeight="bold"
                color="#333"
                fontFamily="Roboto-Bold,Roboto"
                lineHeight="60px">
                {WorldWide.WorldwideSituation}
              </Box>
              <Box
                fontSize="12px"
                color="#333"
                fontWeight={400}
                fontFamily="Roboto-Bold,Roboto">
                {!isLeft && <span>{dataParams.updateTime}</span>}
                {WorldWide.DataUpdateOn}
                {isLeft && <span>{dataParams.updateTime}</span>}
              </Box>
            </Box>
            <Box margin="0 20px" overflow="hidden">
              <WorldBlock
                DataBackgroundColor={BackgroundColor}
                dataParams={dataParams}
                WorldWide={WorldWide}
                DataColor={DataColor}
                region={region}
              />
              <Box
                background="#fff"
                paddingTop="20px"
                borderRadius="0 0 40px 40px">
                <Box textAlign="center" padding="16px 0">
                  <Box
                    color="#434343"
                    fontSize="16px"
                    fontFamily="Roboto-Regular,Roboto">
                    {currentWorldTitle}
                  </Box>
                </Box>
                {<Box>{this.renderCharts(currentWorldOption)}</Box>}
                <Box color="#B9B499" padding="0 10px 10px">
                  {Local.Diagram}
                </Box>
                <Box margin="10px" position="relative">
                  <Box display="flex" width="100%">
                    <ButtonItem
                      title={Local.NewCases}
                      onClick={() => {
                        this.setState({
                          worldClick: 'newCases',
                          currentWorldOption: worldAddOption,
                          currentWorldTitle: WorldWide.newCaseTitle
                        });
                        sensorsGA({
                          category: 'Coronavirus',
                          action: 'WorldNewCases',
                          label: isInSnaptube + ''
                        });
                      }}
                      clicked={worldClick == 'newCases'}
                    />
                    <ButtonItem
                      title={isLeft ? Local.TotalConfirmed : Local.Deaths}
                      onClick={() => {
                        this.setState({
                          worldClick: 'totalConfirmed',
                          currentWorldOption: worldOption,
                          currentWorldTitle: WorldWide.totalConfirmTitle
                        });
                        sensorsGA({
                          category: 'Coronavirus',
                          action: 'WorldTotalConfirmed',
                          label: isInSnaptube + ''
                        });
                      }}
                      clicked={worldClick == 'totalConfirmed'}
                    />
                    <ButtonItem
                      title={isLeft ? Local.Recovered : Local.PresentConfirmed}
                      onClick={() => {
                        this.setState({
                          worldClick: 'heal',
                          currentWorldOption: worldRecoverOption,
                          currentWorldTitle: WorldWide.recoverTitle
                        });
                        sensorsGA({
                          category: 'Coronavirus',
                          action: 'WorldHeal',
                          label: isInSnaptube + ''
                        });
                      }}
                      clicked={worldClick == 'heal'}
                    />
                    <ButtonItem
                      title={isLeft ? Local.Deaths : Local.TotalConfirmed}
                      onClick={() => {
                        this.setState({
                          worldClick: 'deaths',
                          currentWorldOption: worldDeathOption,
                          currentWorldTitle: WorldWide.deathTitle
                        });
                        sensorsGA({
                          category: 'Coronavirus',
                          action: 'WorldDeaths',
                          label: isInSnaptube + ''
                        });
                      }}
                      clicked={worldClick == 'deaths'}
                    />
                  </Box>
                </Box>
              </Box>

            </Box>
            {CountryBlock.show && (
              <CountryModalBlock
                region={region}
                isInSnaptube={isInSnaptube}
                CountryBlock={CountryBlock}
                CountryIndex={CountryIndex}
                CountryColorIndex={CountryColorIndex}
                AreaList={areaList}
                tableColor={tableColor}
              />
            )}
          </Box>
        </Box>

        {
          !isInSnaptube &&
          <Box width="50%" margin='auto'>
            <Box
              component="img"
              width="100%"
              props={{
                onClick: () => {
                  sensorsGA({
                    category: 'Coronavirus',
                    action: 'download_detail',
                    label: isInSnaptube + ''
                  });
                  window.location.href =
                    'http://dl-master.snaptube.app/installer/snaptube/latest/Click_me_to_install_SnapTube_tube_coronavirus.apk';
                },
                src: require(`../asset/${area}/btn_download.png`)
              }}
            />
          </Box>
        }

        <Box
          height="5vh"

        />
        <Box props={{
          id: 'prevention'
        }}
          marginBottom='70px'>
          {/* 文章模块 */}
          {VideoBlock.count && (
            <VideoListBlock
              isLeft={isLeft}
              history={this.props.history}
              VideoBlock={VideoBlock}
              VideoList={VideoList}
              ModuleList={ModuleList}
              isInSnaptube={isInSnaptube}
              region={region}
              type={'detail'}
            />
          )}
        </Box>


        {/* 悬浮下载分享按钮 */}
        {this.state.showFloatButton && (
          <Share
            uri={`https://share.getsnap.link/campaigns/coronavirus/coronavirus?region=${region}`}
            title={shareDes}
            image={require('../asset/sharePic.png')}
            campaign="coronavirus"
            description=""
            isInSnaptube={isInSnaptube}
            isNativeShareUriShorten={true}>
            {({ onClick }) => (
              <Box>
                <Box
                  zIndex={9}
                  width="100%"
                  background="rgba(255,255,255,0.7)"
                  paddingTop="5px"
                  position="fixed"
                  bottom="0">
                  <Box
                    position="relative"
                    props={{
                      onClick: () => {
                        sensorsGA({
                          category: 'Coronavirus',
                          action: 'share_detail',
                          label: isInSnaptube + ''
                        });
                        onClick();
                      }
                    }}>
                    <Box position='relative' width='100%'>
                      <Box
                        position="relative"
                        left='50%'
                        transform='translateX(-50%)'
                        component="img"
                        width="70%"
                        props={{ src: require(`../asset/${area}/btn_share_l.png`) }}
                      />
                    </Box>
                    <Box fontSize='12px' textAlign='center' color='#8C8C8C' position='relative' top='-5px'>
                      {ShareBlock.ShareTips}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Share>
        )}
      </Box>
    );
  }
}

export default NewDetail;
