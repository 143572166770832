import * as React from 'react';
import { Box } from 'jsxstyle';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import { sensorsGA } from '../../../utils';
import queryString from 'query-string';
import countryJson from '../json/country.json';

const rarList = countryJson.rarList;
const renList = countryJson.renList;
const resList = countryJson.resList;
const rptList = countryJson.rptList;
const leftList = countryJson.leftList;

let clientWidth = 360;

class VideoList extends React.Component {
  static propTypes = {
    location: PropTypes.any,
    isInSnaptube: PropTypes.any,
    history: PropTypes.any
  };
  constructor(props) {
    super(props);
    this.state = {
      region: 'EG',
      myConfig: {},
      area: 'REN',
      list: []
    };
  }

  componentDidMount() {
    const { isInSnaptube } = this.props;
    let { region = 'EG', ModuleName } = queryString.parse(
      this.props.location.search
    );
    console.log(region);
    if (Array.isArray(region)) {
      region = region[0];
    }
    let area = region;
    //check country
    if (resList.includes(region)) {
      area = 'RES';
    } else if (rarList.includes(region)) {
      area = 'RAR';
    } else if (renList.includes(region)) {
      area = 'REN';
    } else if (rptList.includes(region)) {
      area = 'RPT';
    }

    clientWidth = document.documentElement.clientWidth;

    if (!leftList.includes(area)) {
      this.setState({
        isLeft: false
      });
    }
    this.setState(
      {
        isInSnaptube,
        area,
        region,
        ModuleName
      },
      () => {
        this.getData();
      }
    );
  }

  onVideoStart = (isInSnaptube, id, ModuleName, index) => {
    sensorsGA({
      category: 'Coronavirus',
      action: `${index}_videolist_videoClick_${ModuleName}_` + isInSnaptube,
      label: id + ''
    });
  };

  getData = async () => {
    let { region, ModuleName } = this.state;
    let windowData = window.__Data__ || {}
    if (!windowData.Item) {
      let url = `https://me0xn4hy3i.execute-api.us-east-1.amazonaws.com/staging/api/coronavirus/newGet?lang=${region}`;
      await fetch(url, {
        method: 'get',
        headers: {}
      })
        .then(response => response.json())
        .then(res => {
          windowData = res.data
        });
    }

    let item = windowData && windowData.Item;
    let myConfig = item.config;
    let currentList = myConfig.ModuleList[ModuleName];

    this.setState({
      myConfig,
      list: currentList
    });

  };

  render() {
    let { VideoBlock = {} } = this.state.myConfig;
    let { isInSnaptube, ModuleName, list } = this.state;
    let videoWidth = clientWidth * 9 / 16;

    let { history } = this.props;
    return (
      <Box>
        <Box position="relative">
          <Box
            component="img"
            width="100%"
            props={{ src: require('../asset/pic_video_title.png') }}
          />
          <Box
            component="img"
            width="7%"
            position="absolute"
            top="50%"
            transform="translateY(-50%)"
            left="5px"
            zIndex={99}
            props={{
              src: require('../asset/icon_back.png'),
              onClick: () => {
                history.goBack();
              }
            }}
          />
          <Box
            position="absolute"
            color="#003060"
            width="100%"
            textAlign="center"
            top="50%"
            transform="translateY(-50%)"
            fontSize="30px"
            fontWeight="bold">
            {' '}
            {VideoBlock[ModuleName]}
          </Box>
        </Box>
        <Box position="relative" top="-5px">
          {list.map((v, index) => {
            return (
              <Box
                width="100%"
                key={index}
                marginBottom="10px"
                background="#fff"
                borderRadius="5px">
                <YouTube
                  videoId={v.id}
                  onPlay={() =>
                    this.onVideoStart(isInSnaptube, v.id, ModuleName, index)
                  }
                  opts={{
                    width: '100%',
                    height: `${videoWidth}`
                  }}
                />
                <Box
                  fontSize="14px"
                  fontWeight={500}
                  color="#434343"
                  padding="5px"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  display="-webkit-box"
                  WebkitBoxOrient="vertical"
                  WebkitLineClamp={2}
                  wordBreak="break-word"
                  wordWrap="break-word">
                  {v.title}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }
}

export default VideoList;
