/* eslint-disable react/display-name */
import * as React from 'react';
import InSnaptube from '@mobiuspace/in-snaptube';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Box, Col } from 'jsxstyle';
import NewDetail from './components/newDetail';
import NewIndex from './components/newIndex';
import Helmet from 'react-helmet';
import VideoList from './components/video';

import Download from './components/download';





const renderRoute = Comp => props => {
  let { ssrData, isLeft, region, area } = props.staticContext || {}

  return (
    <InSnaptube>
      {({ isInSnaptube }) => (
        <Comp isInSnaptube={isInSnaptube} ssrData={ssrData} isLeft={isLeft} region={region} area={area} {...props} />
      )}
    </InSnaptube>
  )
};
export default withRouter(({ match }) => {
  return (
    <Box>
      <Helmet>
        <link rel="preconnect" href="https://s.ytimg.com" />
        <link rel="preconnect" href="http://i3.ytimg.com" />
        <link rel="preconnect" href="https://www.youtube.com" />
        <link
          rel="preconnect"
          href="https://me0xn4hy3i.execute-api.us-east-1.amazonaws.com"
        />
      </Helmet>
      <Switch>
        <Route
          path={`${match.url}/DescargaSnaptube`}
          component={renderRoute(Download)}
        />
        <Route
          path={`${match.url}/detail`}
          component={renderRoute(NewDetail)}
        />
        <Route
          path={`${match.url}/videoList`}
          component={renderRoute(VideoList)}
        />
        <Route
          path={`${match.url}/coronavirus`}
          component={renderRoute(NewDetail)}
        />
        <Route
          path={`${match.url}/coronavirus/videoList`}
          component={renderRoute(VideoList)}
        />
        <Route path={`${match.url}`} component={renderRoute(NewIndex)} />
      </Switch>
    </Box>
  );
});
