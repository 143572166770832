import * as React from 'react';
import { Box } from 'jsxstyle';
import PropTypes from 'prop-types';

class WorldBlock extends React.Component {
  static propTypes = {
    dataParams: PropTypes.any,
    WorldWide: PropTypes.any,
    DataColor: PropTypes.any
  };
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() { }

  //千分位处理
  format = (num, op) => {
    let { region } = this.props;
    let number = num || 0;
    let matchText = '$&,';
    if (region == 'BR') {
      matchText = '$&.';
    }
    if (op) {
      if (number >= 0) {
        number = '+' + number;
      }
    }
    let reg = /\d{1,3}(?=(\d{3})+$)/g;
    return (number + '').replace(reg, matchText);
  };

  render() {
    let {
      dataParams = {},
      WorldWide = {},
      DataColor = {},
      DataBackgroundColor = {}
    } = this.props;

    return (
      <Box background="#fff" display="flex" overflowX='scroll'>
        <Box
          background={DataBackgroundColor.TotalConfirmed}
          width="25%"
          textAlign="center"
          flex={1}
          padding="5px"
          borderRight="1px solid #fff">
          <Box fontSize="10px" lineHeight="16px">
            {WorldWide.Newly}
            <Box color={DataColor.TotalConfirmed} component="span">
              {this.format(dataParams.worldNewTotal, true)}
            </Box>
          </Box>
          <Box
            fontSize="16px"
            fontWeight={900}
            lineHeight="24px"
            fontFamily="Roboto-Black,Roboto"
            color={DataColor.TotalConfirmed}>
            {this.format(dataParams.worldTotal)}
          </Box>
          <Box fontSize="10px">{WorldWide.TotalConfirmed}</Box>
        </Box>
        <Box
          background={DataBackgroundColor.PresentConfirmed}
          width="25%"
          textAlign="center"
          flex={1}
          padding=" 5px"
          borderRight="1px solid #fff">
          <Box fontSize="10px" lineHeight="16px">
            {WorldWide.Newly}
            <Box color={DataColor.PresentConfirmed} component="span">
              {this.format(dataParams.worldNewPresent, true)}
            </Box>
          </Box>
          <Box
            fontSize="16px"
            fontWeight={900}
            lineHeight="24px"
            fontFamily="Roboto-Black,Roboto"
            color={DataColor.PresentConfirmed}>
            {this.format(dataParams.worldPresent)}
          </Box>
          <Box fontSize="10px">{WorldWide.PresentConfirmed}</Box>
        </Box>
        <Box
          background={DataBackgroundColor.Recovered}
          width="25%"
          textAlign="center"
          flex={1}
          padding="5px"
          borderRight="1px solid #fff">
          <Box fontSize="10px" lineHeight="16px">
            {WorldWide.Newly}
            <Box color={DataColor.Recovered} component="span">
              {this.format(dataParams.worldNewHeal, true)}
            </Box>
          </Box>
          <Box
            fontSize="16px"
            fontWeight={900}
            lineHeight="24px"
            fontFamily="Roboto-Black,Roboto"
            color={DataColor.Recovered}>
            {this.format(dataParams.worldHeal)}
          </Box>
          <Box fontSize="10px">{WorldWide.Recovered}</Box>
        </Box>
        <Box
          textAlign="center"
          padding="5px"
          flex={1}
          width="25%"
          background={DataBackgroundColor.Deaths}>
          <Box fontSize="10px" lineHeight="16px">
            {WorldWide.Newly}
            <Box color={DataColor.Deaths} component="span">
              {this.format(dataParams.worldNewDead, true)}
            </Box>
          </Box>
          <Box
            fontSize="16px"
            fontWeight={900}
            lineHeight="24px"
            fontFamily="Roboto-Black,Roboto"
            color={DataColor.Deaths}>
            {this.format(dataParams.worldDead)}
          </Box>
          <Box fontSize="10px">{WorldWide.Deaths}</Box>
        </Box>
      </Box>
    );
  }
}

export default WorldBlock;
