import * as React from 'react';
import { Box } from 'jsxstyle';
import { sensorsGA } from '../../../../utils';


let t1 = 0;
let t2 = 100;
class CountryBlock extends React.Component {
  static propTypes = {};
  constructor(props) {
    super(props);

    this.state = {
      tabStyle: 'relative',
      visible: false,
      scaleList: [1, 1, 1, 1, 1, 1]
    };
  }
  componentDidMount() {


    const self = this;
    var intersectionObserver = new IntersectionObserver(function (entries) {
      if (entries[0].target.id == 'countryBlock') {
        if (entries[0].intersectionRatio > 0) {
          self.setState({
            visible: true
          });
        } else {
          self.setState({
            tabStyle: 'relative',
            visible: false
          });
        }
      }
    });

    intersectionObserver.observe(document.querySelector('#countryBlock'));
    //滑动节流
    const debounce = (fn, wait) => {
      const self = this;
      let timeout = null;
      return function () {
        if (!timeout) {
          timeout = setTimeout(() => {
            timeout = null;
            fn(self);
          }, wait);
        }
      };
    };
    function pageScroll(self) {
      let { visible, isScale } = self.state
      if (!isScale && visible) {
        t1 = document.documentElement.scrollTop || document.body.scrollTop;
        t2 = document.getElementById('countryBlock').offsetTop;

        if (t1 > t2 - 5) {
          self.setState({
            tabStyle: 'fixed'
          });
        } else {
          self.setState({
            tabStyle: 'relative'
          });
        }
      }

    }
    window.addEventListener('scroll', debounce(pageScroll, 200), {
      passive: true
    });
  }

  //千分位处理
  format = (num, op) => {
    let { region } = this.props;
    let number = num || 0;
    let matchText = '$&,';
    if (region == 'BR') {
      matchText = '$&.';
    }
    if (op) {
      if (number >= 0) {
        number = '+' + number;
      }
    }
    let reg = /\d{1,3}(?=(\d{3})+$)/g;
    return (number + '').replace(reg, matchText);
  };


  render() {
    let {
      CountryBlock = {},
      isInSnaptube,
      CountryIndex = [],
      CountryColorIndex = [],
      AreaList = []
    } = this.props;
    CountryIndex = CountryIndex.filter(v => {
      if (v != " ") {
        return v
      }
    })

    let { scaleList, tabStyle } = this.state;
    return (
      <Box
        backgroundColor="#fff"
        borderRadius="0 0 40px 40px"
        position="relative"
        props={{
          id: 'countryBlock'
        }}
        top="-2px">
        {/* <Box> {CityBlock.title}</Box> */}
        <Box
          color="#434343"
          fontSize="16px"
          lineHeight="16px"
          margin="0 20px"
          padding="20px 0"
          textAlign="center"
          fontWeight={600}>
          {CountryBlock.title}
        </Box>
        <Box
          margin="0 0 40px"
          overflowX="hidden">
          <table cellSpacing="0" width="100%">
            <tbody>
              <Box component="tr" props={{ id: 'countryHeader' }} position={tabStyle} top={tabStyle == 'fixed' ? '9vw' : ''} zIndex={999}>
                {//生成表格头部
                  CountryIndex.map((v, index) => {
                    return (
                      <Box
                        textAlign="center"
                        key={index}
                        borderRadius="2px"
                        padding="15px 0"
                        fontSize="14px"
                        fontWeight={600}
                        backgroundColor={CountryColorIndex[index]}
                        color={'#434343'}
                        width={v == 'country' ? '40vw' : "20vw"}
                        component="td">
                        {CountryBlock[v]}
                      </Box>
                    );
                  })}
              </Box>
            </tbody>
          </table>
          <Box paddingTop={tabStyle == 'fixed' ? '9vw' : ''}>
            {AreaList.map((v, areaIndex) => {
              return (
                <Box key={areaIndex}>
                  <table cellSpacing="0" width="100%">
                    <tbody>
                      <Box component="tr">
                        {CountryIndex.map((countryTitle, index) => {
                          return (
                            v && (
                              <Box
                                textAlign="center"
                                key={index}
                                borderRadius="2px"
                                padding="15px 0"
                                fontSize="11px"
                                width={countryTitle == 'country' ? '40vw' : "20vw"}
                                fontWeight={500}
                                color={'#434343'}
                                component="td">
                                {this.format(v[countryTitle])}
                              </Box>
                            )
                          );
                        })}
                      </Box>
                    </tbody>
                  </table>
                  <Box borderTop='1px solid #CFCFCF'>
                    <table cellSpacing="0" width="100%">
                      <tbody>
                        {
                          v.countryList && v.countryList.map((countryV, myIndex) => {
                            if (scaleList[areaIndex]) {
                              if (myIndex < 3) {
                                return <Box component="tr" key={myIndex}>
                                  {CountryIndex.map((countryTitle, index) => {

                                    return <Box
                                      textAlign="center"
                                      key={index}
                                      borderRadius="2px"
                                      padding="10px 0"
                                      width={countryTitle == 'country' ? '40%' : "20%"}
                                      fontSize="11px"
                                      fontWeight={500}
                                      background={myIndex % 2 ? '#EBF6F8' : '#F4F9FA'}
                                      color={'#434343'}
                                      component="td">
                                      {this.format(countryV[countryTitle])}
                                    </Box>

                                  }
                                  )}
                                </Box>
                              }
                            } else {
                              return <Box component="tr" key={myIndex}>
                                {CountryIndex.map((countryTitle, index) => {

                                  return <Box
                                    textAlign="center"
                                    key={index}
                                    borderRadius="2px"
                                    padding="10px 0"
                                    width={countryTitle == 'country' ? '40%' : "20%"}
                                    fontSize="11px"
                                    fontWeight={500}
                                    background={myIndex % 2 ? '#EBF6F8' : '#F4F9FA'}
                                    color={'#434343'}
                                    component="td">
                                    {this.format(countryV[countryTitle])}
                                  </Box>

                                }
                                )}
                              </Box>
                            }

                          })
                        }
                      </tbody>
                    </table>
                    <Box>
                      {
                        scaleList[areaIndex] ? (
                          <Box
                            marginTop='1px'
                            background='#EBF6F8'
                            fontWeight={600}
                            bottom="0"
                            position="relative"
                            width="100%"
                            padding="9px"
                            textAlign="center"
                            props={{
                              onClick: () => {
                                let myScaleList = scaleList
                                myScaleList[areaIndex] = 0
                                this.setState({
                                  scaleList: myScaleList
                                });
                              }
                            }}>
                            <Box
                              color="#008DFF"
                              fontSize="14px"
                              textDecoration="underline">
                              {CountryBlock.more}
                            </Box>
                          </Box>
                        ) : ""
                      }
                    </Box>
                  </Box>
                </Box>
              );

            })}
          </Box>
        </Box>
      </Box >
    );
  }
}

export default CountryBlock;
