import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Bundle extends Component {
  static propTypes = {
    load: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired
  };

  state = {
    mod: null
  };

  componentWillMount() {
    this.load(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.load !== this.props.load) {
      this.load(nextProps);
    }
  }

  load = props => {
    this.setState({mod: null});

    props.load(mod =>
      this.setState({
        mod: mod.default ? mod.default : mod
      })
    );
  };

  render() {
    const {mod} = this.state;

    return mod ? this.props.children(mod) : null;
  }
}

export const withBundle = loadBundle => {
  return class WithBundle extends Component {
    render() {
      const children = Component =>
        Component ? <Component {...this.props} /> : null;

      return <Bundle load={loadBundle}>{children}</Bundle>;
    }
  };
};
